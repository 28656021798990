import { Container, AppBar, Toolbar, Typography, IconButton, Skeleton } from '@mui/material';
import { AccessTimeOutlined, ArrowBackOutlined, CodeOutlined, InfoOutlined, LocalPhoneOutlined, LocationOnOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import * as React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import axios from 'axios';
import Cookies from 'js-cookie';
import LoadingComponent from '../components/LoadingComponent';
import Snackbar from '@mui/material/Snackbar';

import '@fontsource/roboto/400.css';

function Informacoes() {

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "255, 136, 77");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "100, 116, 139");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "117, 117, 163");
  const [descricao, setDescricao] = useState("")
  const [endereco, setEndereco] = useState("")
  const [horarios, setHorarios] = useState("")
  const [telefones, setTelefones] = useState("")

  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleBack = () => {
    navigate("/", { replace: true, state: { page: page, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
  };

  useEffect(() => {
    handleOpenLoading();
    
    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;
    
    if(colorPrimary) {
      setColorPrimary(Cookies.get("clPmr"))
      setColorSecondary(Cookies.get("clScr"))
      setColorTertiary(Cookies.get("clTcr"))
    }

    getInfos()
  }, []);

  const getInfos = () => {
    const mainId = Cookies.get('Md');
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/estabelecimentos.php`, {
      idestabelecimentos: mainId
    })
      .then(response => {
        const informacoes = response.data[0]
        const enderecoArr = informacoes.endereco.split("___")
        setDescricao(informacoes.descricao)
        setEndereco(enderecoArr[0])
        setHorarios(informacoes.horarios)
        setTelefones(informacoes.telefones)
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getHorarios = (horarios) => {
    const horariosArr = horarios.split(",")
    return <>
      Segunda: {horariosArr[0]}<br />
      Terça: {horariosArr[1]}<br />
      Quarta: {horariosArr[2]}<br />
      Quinta: {horariosArr[3]}<br />
      Sexta: {horariosArr[4]}<br />
      Sabado: {horariosArr[5]}<br />
      Domingo: {horariosArr[6]}<br />
    </>
  }

  const getTelefones = (telefones) => {
    const telefonesArray = telefones.split(",");
    return <>
      {telefonesArray[0] && (
        <>
          Telefone 1: {telefonesArray[0]}<br />
        </>
      )}
      {telefonesArray[1] && (
        <>
          Telefone 2: {telefonesArray[1]}<br />
        </>
      )}
      {telefonesArray[2] && (
        <>
          Telefone 3: {telefonesArray[2]}<br />
        </>
      )}
      {telefonesArray[3] && (
        <>
          Telefone 4: {telefonesArray[3]}<br />
        </>
      )}
    </>
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Informações
          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <div style={{ height: "70px" }}></div>

        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <InfoOutlined style={{ color: "#1B1B1B" }} />
            </ListItemAvatar>
            <ListItemText
              primary="Sobre nós"
              primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
              secondary={
                <React.Fragment>
                  <Typography style={{ fontSize: "14px", color: "#999" }}>
                    {descricao ? (
                      descricao
                    ) : (
                      <Skeleton animation="wave" height={30} width="100%" />
                    )}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <LocationOnOutlined style={{ color: "#1B1B1B" }} />
            </ListItemAvatar>
            <ListItemText
              primary="Endereço"
              primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
              secondaryTypographyProps={{ color: "#999999", fontSize: "14px" }}
              secondary={
                <React.Fragment>
                  <Typography style={{ fontSize: "14px", color: "#999" }}>
                    {endereco ? (
                      endereco
                    ) : (
                      <Skeleton animation="wave" height={30} width="100%" />
                    )}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <AccessTimeOutlined style={{ color: "#1B1B1B" }} />
            </ListItemAvatar>
            <ListItemText
              primary="Horário"
              primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
              secondaryTypographyProps={{ color: "#999999", fontSize: "14px" }}
              secondary={
                <React.Fragment>
                  <Typography style={{ fontSize: "14px", color: "#999" }}>
                    {horarios ? (
                      getHorarios(horarios)
                    ) : (
                      <Skeleton animation="wave" height={30} width="100%" />
                    )}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <LocalPhoneOutlined style={{ color: "#1B1B1B" }} />
            </ListItemAvatar>
            <ListItemText
              primary="Telefone"
              primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
              secondaryTypographyProps={{ color: "#999999", fontSize: "14px" }}
              secondary={
                <React.Fragment>
                  <Typography style={{ fontSize: "14px", color: "#999" }}>
                    {telefones ? (
                      getTelefones(telefones)
                    ) : (
                      <Skeleton animation="wave" height={30} width="100%" />
                    )}
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>

          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <CodeOutlined style={{ color: "#1B1B1B" }} />
            </ListItemAvatar>
            <ListItemText
              primary="Sobre nós"
              primaryTypographyProps={{ color: "#1B1B1B", fontWeight: "bold", fontSize: "16px" }}
              secondaryTypographyProps={{ color: "#999999", fontSize: "14px" }}
              secondary={
                <React.Fragment>
                  <Typography style={{ fontSize: "14px", color: "#999" }}>
                    Aplicativo desenvolvido pela empresa Ely com soluções inovadoras para o seu negócio, a Ely visa os mais diversos segmentos empresariais do mercado, com uma equipe altamente qualificada e intega.<br /><br />Veja mais no <a target='_blank' style={{ fontFamily: "roboto", textDecoration: "none", color: "#CC2B02", fontWeight: "600" }} href='https://elysolucoestecnologicas.com.br'>ELY Soluções Tecnológicas</a>
                  </Typography>
                </React.Fragment>
              }
            />
          </ListItem>
        </List>

        <div style={{ height: "20px" }}></div>


        <div style={{ position: "relative", bottom: "0px", right: "0", left: "0", padding: "12px 16px 16px 32px" }}>
          <Typography style={{ textAlign: "center", fontSize: "11px", fontWeight: "bold", color: `#1B1B1B`, paddingTop: "4px" }}>
            <img src="https://dedicado.mely.online/imagens/mely-logo-white.png" style={{ width: "50px"}}/><br />
            v2.0.2.1
          </Typography>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default Informacoes;