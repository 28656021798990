import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab } from '@mui/material';
import { AppShortcutOutlined, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

function Home() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const [background, setBackground] = useState("");
  const [urlBase, setUrlBase] = useState("");
  const [statusEstabelecimento, setStatusEstabelecimento] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [logo, setLogo] = useState("");

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const navigate = useNavigate();

  const handleNovoAgendamento = () => {
    navigate('/novo-agendamento');
  }

  const handleMeusAgendamentos = () => {
    navigate('/meus-agendamentos');
  }

  const handlePerfil = () => {
    navigate('/perfil');
  }

  const handleInformacoes = () => {
    navigate('/informacoes');
  }

  useEffect(() => {

    let urlBase = window.location.href;
    if (urlBase.includes("?")) {
      let arrUrl = urlBase.split("?");
      urlBase = arrUrl[0];
    }
    if (urlBase.includes("localhost")) {
      getConfig("https://espacobeautyeacademy.mely.online/");
      // urlBase = "https://acarajedoj.mely.online/"
      // urlBase = "https://rea.mely.online/"
    } else {
      getConfig(urlBase)
    }

    if(Cookies.get("isLogged") && Cookies.get("isStore")) {
      navigate('/estabelecimento');
    }
    
  }, []);

  const getConfig = (url) => {
    axios.get(`${url}api/get-conf.php`, {})
      .then(response => {
        const arrConf = response.data.split(`\n`)
        let isFacebook = false, tagFacebook = ``
        for (const variable of arrConf) {
          if (variable.includes("mainName")) {
            const mainName = variable.replace("const mainName = ", "").replaceAll("\"", "")
            document.title = mainName;
            Cookies.set('Mn', mainName, { expires: 7 });
          }
          if (variable.includes("timeDelivery")) {
            const timeDelivery = variable.replace("const timeDelivery = ", "").replaceAll("\"", "")
            const arrTime = timeDelivery.split("(")
            Cookies.set('tD', timeDelivery, { expires: 7 });
          }
          if (variable.includes("tagGoogle")) {
            const tagGoogle = variable.replace("const tagGoogle = ", "").replaceAll("\"", "")
            // setAnalytics(tagGoogle)
          }

          if (variable.includes("tagFacebook") || (isFacebook && !variable.includes("noscript"))) {
            const parseTagFacebook = variable.replace("const tagFacebook = ", "").replaceAll("\`", "")
            tagFacebook += parseTagFacebook + `
`;
            isFacebook = true
          } else if (variable.includes("noscript")) {
            const parseTagFacebook = variable
            tagFacebook += parseTagFacebook
            isFacebook = false
            // setTagPixel(tagFacebook)
          }

          if (variable.includes("mainId")) {
            const mainId = variable.replace("const mainId = ", "")
            // getInfos(url, mainId)
            getHorarios(url, mainId);
            Cookies.set('Md', mainId, { expires: 7 });
          }
          if (variable.includes("urlBase")) {
            const urlBase = variable.replace("const urlBase = ", "").replaceAll("\"", "")
            setUrlBase(urlBase)
            const favicon = document.querySelector('link[rel="icon"]');
            favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;
            Cookies.set('UBs', urlBase, { expires: 7 });
            setLogo(`${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`);
          }
          if (variable.includes("urlMessage")) {
            const urlMessage = variable.replace("const urlMessage = ", "").replaceAll("\"", "").replaceAll(" // wa.me = whatsapp ------ m.me = messenger", "")
            Cookies.set('UMs', urlMessage, { expires: 7 });
          }
          if (variable.includes("imgBannerPromo")) {
            const bannerPromo = variable.replace("const imgBannerPromo = ", "").replaceAll("\"", "")
            if (bannerPromo === "customimg") {
              // setOpen(true)
            }
          }
          if (variable.includes("valorMinimo")) {
            const valorMinimo = variable.replace("const valorMinimo = ", "").replaceAll("\"", "")
            Cookies.set('vlMnm', valorMinimo, { expires: 7 });
          }
          if (variable.includes("enableStore")) {
            const enableStore = variable.replace("const enableStore = ", "")
            Cookies.set('eNbS', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("enableForBuy")) {
            const enableStore = variable.replace("const enableForBuy = ", "")
            Cookies.set('eNbB', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("onlyMenu")) {
            const enableStore = variable.replace("const onlyMenu = ", "")
            Cookies.set('eNbM', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("const imgBanner = ")) {
            const background = variable.replace("const imgBanner = ", "").replaceAll("\"", "").replaceAll("-banner", "")
            setBackground(background);
          }
          if (variable.includes("const typeStore")) {
            const tipoLoja = variable.replace("const typeStore = ", "").replaceAll("\"", "")
            Cookies.set('tpLj', tipoLoja, { expires: 7 });
          }
        }
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getHorarios = (url, mainId) => {
    axios.post(`${url}api/horarios.php`, {
      idestabelecimentos: mainId,
    })
      .then(response => {
        response = response.data
        const horariosArray = response[0].horarios.split(",");
        const d = new Date();
        let diasemana
        if (d.getDay() == 0) {
          diasemana = horariosArray[6]
        } else {
          diasemana = horariosArray[d.getDay() - 1]
        }
        if (diasemana == "Fechado") {
          Cookies.set('sTA', `<b style="color:#A41D1D">Fechado</b>`, { expires: 7 });
          setStatusEstabelecimento(`<b style="color:#A41D1D">Fechado</b>`)
        } else {
          var unix = Math.round(+new Date() / 1000);
          const horarios = diasemana.split(" às ");
          const horario1 = horarios[0].split(":");
          var unix1 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario1[0], horario1[1]) / 1000);
          const horario2 = horarios[1].split(":");
          var unix2 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario2[0], horario2[1]) / 1000);

          if (parseInt(horario2[0]) < parseInt(horario1[0]) || (horarios[0] == "00:00" && horarios[1] == "00:00")) {
            unix2 += 86400
          }

          const arrTime = Cookies.get("tD").split("(")
          const tempo = arrTime[1].replaceAll(")", "")

          if (unix > unix1 && unix < unix2) {
            Cookies.set('sTA', `<b style="color:#33540E">Aberto</b> até ${horarios[1]} • ${tempo}`, { expires: 7 });

            setStatusEstabelecimento(`<b style="color:#33540E">Aberto</b> até ${horarios[1]} • ${tempo}`)
          } else {
            Cookies.set('sTA', `<b style="color:#A41D1D">Fechado</b> abre às ${horarios[0]}`, { expires: 7 });
            setStatusEstabelecimento(`<b style="color:#A41D1D">Fechado</b> abre às ${horarios[0]}`)
          }
        }

      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }


  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>

        <CardMedia component="img" height="170" image={`https://escolasuperclass.com.br/wp-content/uploads/2021/01/Cabeleireiro-Feminino.jpg`} style={{ position: "absolute", top: "0", left: "0", right: "0" }} onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = "https://escolasuperclass.com.br/wp-content/uploads/2021/01/Cabeleireiro-Feminino.jpg";
        }} />

        {/* <Fab size="small" variant="circular" color="primary" aria-label="add" style={{ height: "38px", width: "38px", position: "absolute", right: "10px", top: "10px" }}>
          <ShareOutlined style={{ fontSize: "22px", color: "#FFF" }} />
        </Fab> */}

        <div style={{ height: "100px" }}></div>

        <Avatar src={`${logo}`} style={{ width: "120px", height: "120px", margin: "auto", border: "solid 10px white" }}/>

        <Typography sx={{ color: '#334155', textAlign: "center", fontWeight: "bold", fontSize: "24px" }}>ESPAÇO BEAUTY E ACADEMY</Typography>

        <div style={{ height: "20px" }}></div>

        <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNovoAgendamento}
              sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
              startIcon={<EventNote style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
            >
              <div style={{ height: "10px" }}></div>
              <Typography variant="button" sx={{ color: 'white' }}>Novo agendamento</Typography>
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleMeusAgendamentos}
              sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
              startIcon={<CalendarMonth style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
            >
              <div style={{ height: "10px" }}></div>
              <Typography variant="button" sx={{ color: 'white' }}>Meus agendamentos</Typography>
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePerfil}
              sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
              startIcon={<PersonOutline style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
            >
              <div style={{ height: "10px" }}></div>
              <Typography variant="button" sx={{ color: 'white' }}>Perfil</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleInformacoes}
              sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
              startIcon={<InfoOutlined style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
            >
              <div style={{ height: "10px" }}></div>
              <Typography variant="button" sx={{ color: 'white' }}>Informações</Typography>
            </Button>
          </Grid>
        </Grid>

      </Container>
    </ThemeProvider>
  );
}

export default Home;