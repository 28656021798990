import { Container, AppBar, Toolbar, IconButton, Typography, Avatar, List, ListItem, ListItemButton, ListItemText, TextField, InputAdornment, OutlinedInput, Button, SwipeableDrawer, Skeleton, CardMedia, Fab, Grid } from '@mui/material';
import { ArrowBackOutlined, CalendarMonth, KeyboardArrowRightOutlined, LogoutOutlined, PersonOutline, ShareOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { stringAvatar } from '../../functions/utils';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';
import * as React from 'react';

import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';
import LoadingComponent from '../../components/LoadingComponent';

import '@fontsource/roboto/400.css';

function HomeEstabelecimento(props) {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const { windowP } = props;
  const [loading, setLoading] = useState(false);
  const [messageSnack, setMessageSnack] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordCadastro, setShowPasswordCadastro] = useState(false);
  const [showPasswordConfirmCadastro, setShowPasswordConfirmCadastro] = useState(false);
  const [nomeUsuario, setNomeUsuario] = useState('');
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [senhaCadastro, setSenhaCadastro] = useState('');
  const [confirmarSenhaCadastro, setSenhaConfirmCadastro] = useState('');
  const [telefoneCadastro, setTelefoneCadastro] = useState('');
  const [nomeCadastro, setNomeCadastro] = useState('');
  const [emailCadastro, setEmailCadastro] = useState('');
  const [emailEsqueceu, setEmailEsqueceu] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [confirmButton, setConfirmButton] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCadastro, setIsCadastro] = useState(false);
  const [isInit, setIsInit] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const container = windowP !== undefined ? () => windowP().document.body : undefined;
  const [modalAlert, setModalAlert] = useState(false);
  const [openSwipe, setOpenSwipe] = useState(false);
  const [background, setBackground] = useState("");
  const [urlBase, setUrlBase] = useState("");
  const [statusEstabelecimento, setStatusEstabelecimento] = useState("");
  const [logo, setLogo] = useState("");

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenSwipe(newOpen);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnack = (title) => {
    setMessageSnack(title)
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleExcluir = () => {
    setTitle("Atenção");
    setDescription("Essa é uma ação irreversível, você confirma a exclusão total de sua conta?");
    setConfirmButton(true)
    setModalAlert(true);
  };

  const handleOpenModal = (titleModal, descriptionModal) => {
    setConfirmButton(false)
    setTitle(titleModal);
    setDescription(descriptionModal);
    setModalAlert(true);
    handleCloseLoading();
  };

  const handleDelete = () => {
    handleOpenLoading();

    const idusuarios = Cookies.get('iUsr');
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/deletar-conta.php`, {
      idusuarios: idusuarios,
    })
      .then(response => {
        response = response.data
        if (!response) {
          handleOpenModal("", "Não foi possivel deletar sua conta, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "falha") {
          handleOpenModal("", "Não foi possivel deletar sua conta, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "sucesso") {
          handleSair();
          handleCloseLoading();
          return;
        }
        handleOpenModal("", "Não foi possivel deletar sua conta, verifique sua conexão e tente novamente.")
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    setModalAlert(false);
  };

  const handleCloseModal = () => {
    setModalAlert(false);
  };

  const handleMinhaAgenda = () => {
    navigate('/estabelecimento/agenda');
  };

  const handleMeuPerfil = () => {
    navigate('/estabelecimento/perfil');
  };

  const handleMenuClickShowPassword = () => setShowPassword((show) => !show);
  const handleMenuClickShowPasswordCadastro = () => setShowPasswordCadastro((show) => !show);
  const handleMenuClickShowPasswordConfirmCadastro = () => setShowPasswordConfirmCadastro((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    handleOpenLoading();

    if (Cookies.get('UBs')) {
      const mainName = Cookies.get('Mn');
      document.title = mainName;
      const urlBase = Cookies.get('UBs');
      const favicon = document.querySelector('link[rel="icon"]');
      favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;
      setLogo(`${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`);
      console.log(`${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`)
    } else {
      let urlBase = window.location.href;
      if (urlBase.includes("?")) {
        let arrUrl = urlBase.split("?");
        urlBase = arrUrl[0];
      }
      if (urlBase.includes("localhost")) {
        getConfig("https://espacobeautyeacademy.mely.online/");
        // urlBase = "https://acarajedoj.mely.online/"
        // urlBase = "https://rea.mely.online/"
      } else {
        getConfig(urlBase)
      }
    }

    const userIsLogged = Cookies.get('isLogged');
    if(userIsLogged && !Cookies.get('isStore')) {
      navigate('/');
    }
    if (userIsLogged) {
      const nome = Cookies.get('nmUsr');
      setNomeUsuario(nome)
      setIsLogged(true);
    }
    setIsButtonDisabled(!(email !== "" && senha !== ""));
    setIsInit(true);
    handleCloseLoading();
  }, [email, senha]);


  const handleNomeCadastroChange = (event) => {
    setNomeCadastro(event.target.value);
  };

  const handleTelefoneCadastroChange = (event) => {
    setTelefoneCadastro(event.target.value);
  };

  const handleEmailCadastroChange = (event) => {
    setEmailCadastro(event.target.value);
  };

  const handleEmailEsqueceuChange = (event) => {
    setEmailEsqueceu(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setSenha(event.target.value);
  };

  const handlePasswordCadastroChange = (event) => {
    setSenhaCadastro(event.target.value);
  };

  const handlePasswordConfirmCadastroChange = (event) => {
    setSenhaConfirmCadastro(event.target.value);
  };

  const handleEsqueciSenha = (event) => {
    setIsCadastro(false);
    setOpenSwipe(true);
  };

  const handleCadastro = (event) => {
    handleOpenLoading();

    if (!emailCadastro || !nomeCadastro || !telefoneCadastro || !senhaCadastro || !confirmarSenhaCadastro) {
      handleOpenModal("Campos vazios", "Você precisa preencher todos os campos para cadastrar-se")
      return
    }

    if (senhaCadastro !== confirmarSenhaCadastro) {
      handleOpenModal("Senhas não conferem", "As senhas digitadas não conferem, verifique e tente novamente")
      return
    }

    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/cadastro.php`, {
      nome: nomeCadastro.trim(),
      telefone: telefoneCadastro.trim(),
      email: emailCadastro.trim(),
      senha: senhaCadastro
    })
      .then(response => {
        response = response.data
        if (!response) {
          handleOpenModal("", "Não foi possivel conectar cadastrar-se, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "nome invalido") {
          handleOpenModal("Nome inválido", "O nome digitado não é válido, verifique e tente novamente.")
          return;
        }

        if (response.resposta === "nome especial") {
          handleOpenModal("Nome inválido", "O nome digitado possui caracteres especiais, verifique e tente novamente.")
          return;
        }

        if (response.resposta === "telefone invalido") {
          handleOpenModal("Telefone inválido", "O telefone não é válido, verifique e tente novamente.")
          return;
        }

        if (response.resposta === "email invalido") {
          handleOpenModal("Email inválido", "O email não é válido, verifique e tente novamente.")
          return;
        }

        if (response.resposta === "email cadastrado") {
          handleOpenModal("Email cadastrado", "O email digitado já possui um cadastro, verifique e tente novamente.")
          return;
        }

        if (response.resposta === "senha curta") {
          handleOpenModal("Senha curta", "A sua senha precisa ter pelo menos 6 caracteres, verifique e tente novamente.")
          return;
        }

        if (response.resposta === "falha") {
          handleOpenModal("", "Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
          return;
        }

        setNomeUsuario(response[0].nome)
        Cookies.set('isLogged', true, { expires: 7 });
        Cookies.set('emUsr', response[0].email, { expires: 7 });
        Cookies.set('pUsr', response[0].pontos, { expires: 7 });
        Cookies.set('tUsr', response[0].telefone, { expires: 7 });
        Cookies.set('iUsr', response[0].idusuarios, { expires: 7 });
        Cookies.set('nmUsr', response[0].nome, { expires: 7 });

        setIsLogged(true)
        handleSnack("Parabéns, você realizou seu cadastro com sucesso.");
        setOpenSwipe(false);
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  };


  const handleEsqueciASenha = (event) => {
    handleOpenLoading();

    if (!emailEsqueceu) {
      handleOpenModal("Email inválido", "Você precisa digitar um email para continuar")
      return
    }

    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/reenviar-senha.php`, {
      email: emailEsqueceu,
    })
      .then(response => {
        response = response.data
        if (!response) {
          handleOpenModal("", "Não foi possivel reenviar sua senha, verifique sua conexão e tente novamente.")
          return;
        }

        if (response.resposta === "email invalido") {
          handleOpenModal("Email inválido", "O email não é válido, verifique e tente novamente.")
          return;
        }

        if (response.resposta === "email nao cadastrado") {
          handleOpenModal("Email não cadastrado", "O email digitado não possui um cadastro, verifique e tente novamente.")
          return;
        }

        if (response.resposta === "falha") {
          handleOpenModal("", "Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
          return;
        }

        handleSnack("Enviamos um email para redefinição de sua senha");
        setOpenSwipe(false);
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  };


  const handleSair = (event) => {
    setIsLogged(false)
    Cookies.remove('dsEst');
    Cookies.remove('emEst');
    Cookies.remove('endEst');
    Cookies.remove('fMsP');
    Cookies.remove('hREst');
    Cookies.remove('iEst');
    Cookies.remove('nmEst');
    Cookies.remove('txEst');
    Cookies.remove('tEst');
    Cookies.remove('tsEst');
    Cookies.remove('UBs');
    Cookies.remove('isLogged');
    Cookies.remove('isStore');
  };

  const handleLogin = () => {
    handleOpenLoading();

    if (email !== "" && senha !== "") {
      const urlBase = Cookies.get('UBs');
      axios.post(`${urlBase}/login-estabelecimento.php`, {
        email: email,
        senha: senha
      })
        .then(response => {
          response = response.data
          if (!response) {
            handleOpenModal("Usuário inválido", "A senha digitada para o e-mail é inválida, se estiver com problemas em sua senha recupere clicando em esqueci a senha.")
            return;
          }
          if (response.resposta === "email invalido") {
            handleOpenModal("E-mail inválido", "O e-mail digitado não é válido, verifique e tente novamente.")
            return;
          }
          setNomeUsuario(response[0].nome)
          for (let user of response) {
            Cookies.set('dsEst', user.descricao, { expires: 7 });
            Cookies.set('emEst', user.email, { expires: 7 });
            Cookies.set('endEst', user.endereco, { expires: 7 });
            Cookies.set('fMsP', user.formas_pagamento, { expires: 7 });
            Cookies.set('hREst', user.horarios, { expires: 7 });
            Cookies.set('iEst', user.idestabelecimentos, { expires: 7 });
            Cookies.set('nmEst', user.nome, { expires: 7 });
            Cookies.set('txEst', user.taxa_entrega, { expires: 7 });
            Cookies.set('tEst', user.telefones, { expires: 7 });
            Cookies.set('tsEst', user.tipo_segmento, { expires: 7 });
            Cookies.set('UBs', user.url_base, { expires: 7 });
            Cookies.set('isLogged', true, { expires: 7 });
            Cookies.set('isStore', true, { expires: 7 });
          }
          setIsLogged(true);
          handleCloseLoading();
        })
        .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    }
  };

  const getConfig = (url) => {
    axios.get(`${url}api/get-conf.php`, {})
      .then(response => {
        const arrConf = response.data.split(`\n`)
        let isFacebook = false, tagFacebook = ``
        for (const variable of arrConf) {
          if (variable.includes("mainName")) {
            const mainName = variable.replace("const mainName = ", "").replaceAll("\"", "")
            document.title = mainName;
            Cookies.set('Mn', mainName, { expires: 7 });
          }
          if (variable.includes("timeDelivery")) {
            const timeDelivery = variable.replace("const timeDelivery = ", "").replaceAll("\"", "")
            const arrTime = timeDelivery.split("(")
            Cookies.set('tD', timeDelivery, { expires: 7 });
          }
          if (variable.includes("tagGoogle")) {
            const tagGoogle = variable.replace("const tagGoogle = ", "").replaceAll("\"", "")
            // setAnalytics(tagGoogle)
          }

          if (variable.includes("tagFacebook") || (isFacebook && !variable.includes("noscript"))) {
            const parseTagFacebook = variable.replace("const tagFacebook = ", "").replaceAll("\`", "")
            tagFacebook += parseTagFacebook + `
`;
            isFacebook = true
          } else if (variable.includes("noscript")) {
            const parseTagFacebook = variable
            tagFacebook += parseTagFacebook
            isFacebook = false
            // setTagPixel(tagFacebook)
          }

          if (variable.includes("mainId")) {
            const mainId = variable.replace("const mainId = ", "")
            // getInfos(url, mainId)
            getHorarios(url, mainId);
            Cookies.set('Md', mainId, { expires: 7 });
          }
          if (variable.includes("urlBase")) {
            const urlBase = variable.replace("const urlBase = ", "").replaceAll("\"", "")
            setUrlBase(urlBase)
            const favicon = document.querySelector('link[rel="icon"]');
            favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;
            Cookies.set('UBs', urlBase, { expires: 7 });
            setLogo(`${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`);
          }
          if (variable.includes("urlMessage")) {
            const urlMessage = variable.replace("const urlMessage = ", "").replaceAll("\"", "").replaceAll(" // wa.me = whatsapp ------ m.me = messenger", "")
            Cookies.set('UMs', urlMessage, { expires: 7 });
          }
          if (variable.includes("imgBannerPromo")) {
            const bannerPromo = variable.replace("const imgBannerPromo = ", "").replaceAll("\"", "")
            if (bannerPromo === "customimg") {
              // setOpen(true)
            }
          }
          if (variable.includes("valorMinimo")) {
            const valorMinimo = variable.replace("const valorMinimo = ", "").replaceAll("\"", "")
            Cookies.set('vlMnm', valorMinimo, { expires: 7 });
          }
          if (variable.includes("enableStore")) {
            const enableStore = variable.replace("const enableStore = ", "")
            Cookies.set('eNbS', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("enableForBuy")) {
            const enableStore = variable.replace("const enableForBuy = ", "")
            Cookies.set('eNbB', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("onlyMenu")) {
            const enableStore = variable.replace("const onlyMenu = ", "")
            Cookies.set('eNbM', enableStore === "true", { expires: 7 });
          }
          if (variable.includes("const imgBanner = ")) {
            const background = variable.replace("const imgBanner = ", "").replaceAll("\"", "").replaceAll("-banner", "")
            setBackground(background);
          }
          if (variable.includes("const typeStore")) {
            const tipoLoja = variable.replace("const typeStore = ", "").replaceAll("\"", "")
            Cookies.set('tpLj', tipoLoja, { expires: 7 });
          }
        }
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getHorarios = (url, mainId) => {
    axios.post(`${url}api/horarios.php`, {
      idestabelecimentos: mainId,
    })
      .then(response => {
        response = response.data
        const horariosArray = response[0].horarios.split(",");
        const d = new Date();
        let diasemana
        if (d.getDay() == 0) {
          diasemana = horariosArray[6]
        } else {
          diasemana = horariosArray[d.getDay() - 1]
        }
        if (diasemana == "Fechado") {
          Cookies.set('sTA', `<b style="color:#A41D1D">Fechado</b>`, { expires: 7 });
          setStatusEstabelecimento(`<b style="color:#A41D1D">Fechado</b>`)
        } else {
          var unix = Math.round(+new Date() / 1000);
          const horarios = diasemana.split(" às ");
          const horario1 = horarios[0].split(":");
          var unix1 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario1[0], horario1[1]) / 1000);
          const horario2 = horarios[1].split(":");
          var unix2 = Math.round(+new Date(d.getFullYear(), d.getMonth(), d.getDate(), horario2[0], horario2[1]) / 1000);

          if (parseInt(horario2[0]) < parseInt(horario1[0]) || (horarios[0] == "00:00" && horarios[1] == "00:00")) {
            unix2 += 86400
          }

          const arrTime = Cookies.get("tD").split("(")
          const tempo = arrTime[1].replaceAll(")", "")

          if (unix > unix1 && unix < unix2) {
            Cookies.set('sTA', `<b style="color:#33540E">Aberto</b> até ${horarios[1]} • ${tempo}`, { expires: 7 });

            setStatusEstabelecimento(`<b style="color:#33540E">Aberto</b> até ${horarios[1]} • ${tempo}`)
          } else {
            Cookies.set('sTA', `<b style="color:#A41D1D">Fechado</b> abre às ${horarios[0]}`, { expires: 7 });
            setStatusEstabelecimento(`<b style="color:#A41D1D">Fechado</b> abre às ${horarios[0]}`)
          }
        }

      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>

        {isInit ? (
          <>

            <LoadingComponent open={loading} />

            <Snackbar
              open={openSnack}
              autoHideDuration={3000}
              onClose={handleCloseSnack}
              message={messageSnack}
            />

            <Dialog
              open={modalAlert}
              onClose={handleCloseModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {title}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {description}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseModal}>Fechar</Button>
                {confirmButton && (
                  <Button onClick={handleDelete}>Confirmar</Button>
                )}
              </DialogActions>
            </Dialog>

            <div style={{ height: "33px" }}></div>

            {isLogged && (
              <>
                <CardMedia component="img" height="170" image={`https://escolasuperclass.com.br/wp-content/uploads/2021/01/Cabeleireiro-Feminino.jpg`} style={{ position: "absolute", top: "0", left: "0", right: "0" }} onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "https://escolasuperclass.com.br/wp-content/uploads/2021/01/Cabeleireiro-Feminino.jpg";
                }} />

                {/* <Fab size="small" variant="circular" color="primary" aria-label="add" style={{ height: "38px", width: "38px", position: "absolute", right: "10px", top: "10px" }}>
                  <ShareOutlined style={{ fontSize: "22px", color: "#FFF" }} />
                </Fab> */}

                <div style={{ height: "60px" }}></div>

                <Avatar src={`${logo}`} style={{ width: "120px", height: "120px", margin: "auto", border: "solid 10px white" }} />

                <Grid container spacing={2} style={{ display: "flex", justifyContent: "center" }}>
                  {/* <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleMeuPerfil}
                      sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
                      startIcon={<PersonOutline style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
                    >
                      <div style={{ height: "10px" }}></div>
                      <Typography variant="button" sx={{ color: 'white' }}>Meu perfil</Typography>
                    </Button>
                  </Grid> */}

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleMinhaAgenda}
                      sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
                      startIcon={<CalendarMonth style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
                    >
                      <div style={{ height: "10px" }}></div>
                      <Typography variant="button" sx={{ color: 'white' }}>Minha agenda</Typography>
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSair}
                      sx={{ width: '150px', height: '150px', justifyContent: 'center', flexDirection: 'column' }}
                      startIcon={<LogoutOutlined style={{ fontSize: "40px", color: "#FFF", paddingLeft: "8px" }} />}
                    >
                      <div style={{ height: "10px" }}></div>
                      <Typography variant="button" sx={{ color: 'white' }}>Sair</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}

            {!isLogged && (
              <>

                <Typography style={{ fontSize: "22px", color: "#464444", textAlign: "center", fontWeight: "bold" }}>
                  BETA - <b style={{ color: "#4338ca" }}>PULSE</b>
                </Typography>

                <div style={{ height: "25px" }}></div>

                <Typography style={{ fontSize: "22px", color: "#464444", textAlign: "center", fontWeight: "bold" }}>
                  Login estabelecimento
                </Typography>

                <div style={{ height: "25px" }}></div>

                <Typography style={{ fontSize: "12px", color: "#464444", fontWeight: "bold" }}>
                  Email
                </Typography>

                <div style={{ height: "6px" }}></div>

                <TextField
                  fullWidth
                  hiddenLabel
                  value={email}
                  onChange={handleEmailChange}
                  variant="outlined"
                  placeholder="Digite seu email"
                  type="email"
                  style={{ color: "red" }}
                />

                <div style={{ height: "25px" }}></div>

                <Typography style={{ fontSize: "12px", color: "#464444", fontWeight: "bold" }}>
                  Senha
                </Typography>

                <div style={{ height: "6px" }}></div>

                <OutlinedInput
                  fullWidth
                  hiddenLabel
                  value={senha}
                  onChange={handlePasswordChange}
                  variant="outlined"
                  placeholder="Digite sua senha"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleMenuClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <div style={{ height: "10px" }}></div>

                <p style={{ textAlign: "right", margin: "0" }}>
                  <Button variant="text" size="small" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={handleEsqueciSenha}>Esqueci minha senha</Button>
                </p>

                <div style={{ height: "32px" }}></div>

                <Button fullWidth style={{ textTransform: "capitalize" }} disabled={isButtonDisabled} variant="contained" size="large" onClick={handleLogin}>Entrar</Button>

                <div style={{ height: "12px" }}></div>

              </>
            )}

            <SwipeableDrawer
              container={container}

              anchor="bottom"
              open={openSwipe}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              disableSwipeToOpen={true}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <div style={{ backgroundColor: "#B2B2B2", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>

              {isCadastro && (
                <>
                  <Typography style={{ fontSize: "16px", paddingLeft: "20px", color: "#333", fontWeight: "bold" }}>
                    Cadastrar
                  </Typography>
                  <div style={{ height: "10px" }}></div>
                  <Typography style={{ fontSize: "12px", paddingLeft: "20px", color: "#999", fontWeight: "bold" }}>
                    Cadastre-se abaixo e fique ligado em todas as nossas promoções e brindes
                  </Typography>
                  <div style={{ height: "10px" }}></div>

                  <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>

                    <TextField
                      fullWidth
                      hiddenLabel
                      value={nomeCadastro}
                      onChange={handleNomeCadastroChange}
                      variant="outlined"
                      placeholder="Nome Completo"
                      type="text"
                    />

                    <div style={{ height: "16px" }}></div>

                    <TextField
                      fullWidth
                      hiddenLabel
                      value={telefoneCadastro}
                      onChange={handleTelefoneCadastroChange}
                      variant="outlined"
                      placeholder="Telefone"
                      type="text"
                    />

                    <div style={{ height: "16px" }}></div>

                    <TextField
                      fullWidth
                      hiddenLabel
                      value={emailCadastro}
                      onChange={handleEmailCadastroChange}
                      variant="outlined"
                      placeholder="Email"
                      type="email"
                    />

                    <div style={{ height: "16px" }}></div>

                    <OutlinedInput
                      fullWidth
                      hiddenLabel
                      value={senhaCadastro}
                      onChange={handlePasswordCadastroChange}
                      variant="outlined"
                      placeholder="Senha"
                      type={showPasswordCadastro ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleMenuClickShowPasswordCadastro}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordCadastro ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                    <div style={{ height: "16px" }}></div>

                    <OutlinedInput
                      fullWidth
                      hiddenLabel
                      value={confirmarSenhaCadastro}
                      onChange={handlePasswordConfirmCadastroChange}
                      variant="outlined"
                      placeholder="Confirme sua senha"
                      type={showPasswordConfirmCadastro ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleMenuClickShowPasswordConfirmCadastro}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPasswordConfirmCadastro ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />

                  </Typography>

                  <Button variant="contained" size="large" style={{ textTransform: "capitalize", margin: "20px" }} onClick={handleCadastro}>Cadastrar</Button>
                </>
              )}

              {!isCadastro && (
                <>
                  <Typography style={{ fontSize: "16px", paddingLeft: "20px", color: "#333", fontWeight: "bold" }}>
                    Esqueceu a senha
                  </Typography>
                  <div style={{ height: "10px" }}></div>
                  <Typography style={{ fontSize: "12px", paddingLeft: "20px", color: "#999", fontWeight: "bold" }}>
                    Esqueceu a senha? Não se preocupe, digite seu email abaixo para recupera-la
                  </Typography>
                  <div style={{ height: "10px" }}></div>

                  <Typography style={{ paddingLeft: "20px", paddingRight: "22px" }}>

                    <TextField
                      fullWidth
                      hiddenLabel
                      value={emailEsqueceu}
                      onChange={handleEmailEsqueceuChange}
                      variant="outlined"
                      placeholder="Email"
                      type="text"
                    />
                  </Typography>

                  <Button variant="contained" size="large" style={{ textTransform: "capitalize", margin: "20px" }} onClick={handleEsqueciASenha}>Continuar</Button>
                </>
              )}

            </SwipeableDrawer>

            <div style={{ height: "100px" }}></div>
          </>
        ) : (
          <>
            <Skeleton animation="wave" style={{ position: "absolute", top: "0", left: "0", right: "0", borderRadius: "0", height: "80px", marginTop: "-18px" }} />

            <div style={{ height: "70px" }}></div>

            <Typography>
              <Skeleton variant="circular" width={40} height={40} style={{ margin: "auto" }} />
              <Skeleton animation="wave" height={36} width="240px" style={{ margin: "auto" }} />
            </Typography>

            <div style={{ height: "40px" }}></div>

            <List>
              <ListItem disablePadding divider={true}>
                <ListItemButton>
                  <Skeleton animation="wave" height={36} width="240px" style={{ margin: "auto" }} />

                </ListItemButton>
                <KeyboardArrowRightOutlined></KeyboardArrowRightOutlined>
              </ListItem>
              <ListItem disablePadding divider={true}>
                <ListItemButton>
                  <Skeleton animation="wave" height={36} width="240px" style={{ margin: "auto" }} />
                </ListItemButton>
                <KeyboardArrowRightOutlined></KeyboardArrowRightOutlined>
              </ListItem>
              <ListItem disablePadding divider={true}>
                <ListItemButton>
                  <Skeleton animation="wave" height={36} width="240px" style={{ margin: "auto" }} />
                </ListItemButton>
                <KeyboardArrowRightOutlined></KeyboardArrowRightOutlined>
              </ListItem>
            </List>
          </>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default HomeEstabelecimento;