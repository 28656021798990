import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, Divider, Snackbar } from '@mui/material';
import { AppShortcutOutlined, ArrowBackOutlined, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme, toReal } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import * as React from 'react';

function NovoAgendamento(props) {

  const { windows } = props;

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageSnack, setMsgSnack] = useState("");
  const [open, setOpen] = useState(false);
  const [urlBase, setUrlBase] = useState("");
  const [nome, setNome] = useState("");
  const [statusEstabelecimento, setStatusEstabelecimento] = useState("");
  const [carrinho, setCarrinho] = useState([]);
  const [valorTotalCarrinho, setValorTotalCarrinho] = useState("");
  const [pesquisar, setPesquisar] = useState("");
  const [items, setItems] = useState(null);
  const [itemsOrigim, setItemsOrigim] = useState([]);
  const [itemSelected, setItemSelected] = useState([]);
  const [opcionaisSelected, setOpcionaisSelected] = useState({});

  const navigate = useNavigate();
  const container = windows !== undefined ? () => windows().document.body : undefined;

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  useEffect(() => {
    // handleOpenLoading();
    console.log("atualizou")
    const mainName = Cookies.get('Mn');
    document.title = mainName;
    let urlBase = Cookies.get('UBs');
    if (!urlBase) {
      urlBase = window.location.href;
      if (urlBase.includes("?")) {
        let arrUrl = urlBase.split("?");
        urlBase = arrUrl[0];
      }
      urlBase = `${urlBase}/api`;
    }
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;


    setUrlBase(urlBase)
    setNome(mainName)
    const statusAtual = Cookies.get('sTA');
    setStatusEstabelecimento(statusAtual)
    getProdutos(urlBase);

    let currentCarrinho = Cookies.get('crU');
    if (currentCarrinho) {
      currentCarrinho = JSON.parse(atob(currentCarrinho))
      let valorTotal = 0
      setCarrinho(currentCarrinho)
      for (const produto of currentCarrinho) {
        valorTotal += parseFloat(produto.valorTotal.replaceAll(".", "").replaceAll(",", '.'))
      }
      setValorTotalCarrinho(toReal(valorTotal))
    }

  }, []);

  const getProdutos = (url) => {
    axios.get(`${url}/servicos.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        response = response.data
        // ajusta as categorias
        let ultimaCategoria = "", ultimaSubcategoria = "", ultimoProduto = ""
        for (const produto of response) {
          const partes = produto.CT_SV.split(' - ');
          const categoriaNumero = partes[0];
          const categoriaOriginal = partes[1];
          const categoriaProduto = partes[2];
          produto.categoriaNumero = categoriaNumero
          produto.categoriaOriginal = categoriaOriginal
          produto.categoriaProduto = categoriaProduto
          produto.subcategoriaOriginal = produto.SCT_SV
          if (ultimaCategoria == "" || ultimaCategoria != produto.CT_SV) {
            if (!produto.tipo) {
              if (produto.SCT_SV != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.SCT_SV)) {
                ultimaCategoria = produto.CT_SV
                ultimaSubcategoria = produto.SCT_SV
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                continue
              } else {
                if (produto.SCT_SV == "") {
                  ultimaSubcategoria = produto.SCT_SV
                }
                ultimaCategoria = produto.CT_SV
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                continue
              }
            } else {
              if (produto.SCT_SV != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.SCT_SV)) {
                ultimaCategoria = produto.CT_SV
                ultimaSubcategoria = produto.SCT_SV
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                continue
              }
            }
          } else {
            if (produto.SCT_SV != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.SCT_SV)) {
              ultimaCategoria = produto.CT_SV
              ultimaSubcategoria = produto.SCT_SV
              ultimoProduto = categoriaProduto
              produto.isFirst = "true"
              continue
            }
          }

          if (produto.tipo) {
            if (categoriaProduto !== ultimoProduto) {
              produto.isFirst = "true"
            }
          }
          ultimoProduto = categoriaProduto

          produto.CT_SV = ""
          produto.SCT_SV = ""
        }
        handleCloseLoading();
        setItems(response);
        setItemsOrigim(response);
        console.log("response")
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleAgendar = () => {
    let isLogged = Cookies.get('isLogged');
    if (isLogged) {
      Cookies.set('pRSLc', btoa(JSON.stringify(itemSelected)), { expires: 7 });
      navigate('/escolher-profissional');
    } else {
      handleSnack("Realize o login para fazer um novo agendamento.")
      setTimeout(() => {
        navigate('/perfil');
      }, 2000);
    }
  };

  const handleBack = () => {
    navigate('/');
  };

  const handleSelect = (produto) => {
    setItemSelected(produto)
    setOpen(true)
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Serviços
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
          message={messageSnack}
        />

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Serviços disponíveis
        </Typography>

        <div style={{ height: "20px" }}></div>

        {items && (
          <>
            {items.map((produto) => (
              <>
                {produto.isFirst && (
                  <>
                    <Typography style={{ backgroundColor: `rgb(${colorPrimary})`, padding: "10px 10px 10px 2px", color: "white", borderRadius: 7, fontWeight: "bold" }}>
                      {produto.CT_SV}
                    </Typography>
                    <div style={{ height: "20px" }}></div>
                  </>
                )}
                <Grid item>
                  <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {produto.NM_SV}
                  </Typography>
                  <Card variant="outlined">
                    <Grid container>
                      <Grid item xs={2} sx={{ backgroundColor: `rgb(${colorPrimary})`, color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <Typography variant="h6" sx={{ writingMode: 'vertical-lr', textOrientation: 'mixed' }}>{produto.TP_SV} min</Typography>
                      </Grid>
                      <Grid item xs={10} sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${produto.ID_SV_EST}_0.png`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
                          }}
                          alt="Imagem"
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <div style={{ height: "10px" }}></div>
                <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                  <b style={{ float: "left", paddingTop: "8px", paddingLeft: "5px" }}>R$ {produto.VL_SV}</b>
                  <Button style={{ float: "right", color: 'white', backgroundColor: `rgb(${colorPrimary})` }} onClick={() => handleSelect(produto)}>Ver detalhes</Button>
                </Typography>
                <div style={{ height: "60px" }}></div>
                <Divider />
                <div style={{ height: "20px" }}></div>
              </>
            ))}
          </>
        )}

        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >

          <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>

          <img
            width={300}
            style={{ margin: "auto", borderRadius: 15, border: "solid 2px #ccc" }}
            src={`${urlBase.replaceAll("/api", "")}/imagens-produtos/${itemSelected.ID_SV_EST}_0.png`}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "https://dedicado.mely.online/imagens/placeholder.png";
            }}
            alt="Imagem"
          />
          <div style={{ height: "20px" }}></div>
          <Divider style={{ marginLeft: 16, marginRight: 16 }} />
          <div style={{ height: "20px" }}></div>
          <Typography style={{ fontSize: "20px", fontWeight: "bold", paddingLeft: "16px" }}>
            {itemSelected.NM_SV}
          </Typography>
          <Typography style={{ fontSize: "14px", paddingLeft: "16px", color: "#94a3b8" }}>
            {itemSelected.DSC_SV}
          </Typography>
          <div style={{ height: "20px" }}></div>
          <Typography style={{ fontSize: "14px", paddingLeft: "16px", color: "#94a3b8" }}>
            <b>Tempo de serviço: {itemSelected.TP_SV} min</b><br />
            <div style={{ height: "20px" }}></div>
            <b>Observação: {itemSelected.OBS_SV ? itemSelected.OBS_SV : "Sem observações"}</b>
            <div style={{ height: "60px" }}></div>
          </Typography>
          <Typography style={{ fontSize: "20px", fontWeight: "bold", paddingLeft: "16px" }}>
            R$ {itemSelected.VL_SV}
            <Button style={{ float: "right", color: 'white', backgroundColor: `rgb(${colorPrimary})`, marginRight: "16px" }} onClick={handleAgendar}>Agendar</Button>
          </Typography>
          <div style={{ height: "20px" }}></div>
        </SwipeableDrawer>
      </Container>
    </ThemeProvider>
  );
}

export default NovoAgendamento;