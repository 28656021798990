import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, Divider } from '@mui/material';
import { AppShortcutOutlined, ArrowBackOutlined, ArrowForwardIos, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import * as React from 'react';

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

function EscolherProfissional() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [profissionais, setProfissionais] = useState(null);
  const [urlBase, setUrlBase] = useState("");

  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  useEffect(() => {
    // handleOpenLoading();
    let urlBase = Cookies.get('UBs');
    if (!urlBase) {
      urlBase = window.location.href;
      if (urlBase.includes("?")) {
        let arrUrl = urlBase.split("?");
        urlBase = arrUrl[0];
      }
      urlBase = `${urlBase}/api`;
    }

    setUrlBase(urlBase)

    getProfissionais(urlBase);


  }, []);

  const getProfissionais = (url) => {
    axios.get(`${url}/profissionais.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        response = response.data
        setProfissionais(response);
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const handleBack = () => {
    navigate('/novo-agendamento');
  };

  const handleHorarios = (profissional) => {
    Cookies.set('pFSl', profissional.NM_PF, { expires: 7 });
    Cookies.set('IpFSl', profissional.IDPF_EST, { expires: 7 });
    navigate('/horarios');
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>

        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Profissional
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Escolha seu profissional
        </Typography>

        <div style={{ height: "10px" }}></div>

        <Grid item xs={12} md={6}>
          <List dense={dense}>

            {profissionais && (
              <>
                {profissionais.map((profissional) => (
                  <>
                    <ListItem
                      disableGutters
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={() => handleHorarios(profissional)}>
                          <ArrowForwardIos />
                        </IconButton>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar src={`${urlBase.replaceAll("/api", "")}/imagens-profissionais/${profissional.IDPF_EST}.png`} style={{ width: "50px", height: "50px" }} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Typography style={{ paddingLeft: "5px", fontWeight: "bold" }}>{profissional.NM_PF}</Typography>}
                      />
                    </ListItem>
                    <Divider />
                    <div style={{ height: "10px" }}></div>
                  </>
                ))}
              </>
            )}
          </List>
        </Grid>

      </Container>
    </ThemeProvider>
  );
}

export default EscolherProfissional;