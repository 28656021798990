import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, CardHeader } from '@mui/material';
import { AppShortcutOutlined, ArrowBackOutlined, CardMembershipOutlined, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PaymentOutlined, PaymentsOutlined, PersonOutline, PixOutlined, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import LinkIcon from '@mui/icons-material/Link';

import axios from 'axios';
import Cookies from 'js-cookie';

function FormaPagamento() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [valorLevar, setValorLevar] = useState("0,00");
  const [textButton, setTextButton] = useState("Continuar sem troco");
  const [troco, setTroco] = useState("");
  const [formasPagamento, setFormasPagamento] = useState([]);

  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleBack = () => {
    navigate('/horarios');
  };

  const handleCardClick = (cardId) => {
    setSelectedCard(cardId);
    setButtonEnabled(false)
  };

  const handleFinalizar = () => {
    Cookies.set('fmP', selectedCard, { expires: 7 });
    navigate('/finalizar-agendamento');
  };

  useEffect(() => {
    handleOpenLoading();

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    getInfos();
    const pagamento = Cookies.get('fmP');
    setSelectedCard(pagamento);
    if (pagamento) {
      setButtonEnabled(false)
    }
  }, []);

  const getInfos = () => {
    const mainId = Cookies.get('Md');
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/formas-pagamento.php`, {
      idestabelecimentos: mainId
    })
      .then(response => {
        setFormasPagamento(response.data[0]);
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }


  const returnFormas = (formas) => {
    if (!formas.formas_pagamento) {
      return
    }
    const pagamentosArray = formas.formas_pagamento.split("--");
    const creditodebito = pagamentosArray[1].split(",")
    const vrva = pagamentosArray[2].split(",")
    if (pagamentosArray[3]) {
      Cookies.set('cvP', pagamentosArray[3], { expires: 7 });
    }

    return <>
      {pagamentosArray[3] && (
        <>
          <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === 'pix' ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB' }} onClick={() => handleCardClick('pix')}>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              avatar={
                <PixOutlined style={{ color: "#464545" }} />
              }
              title="PIX"
              titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
            />
          </Card>
          <div style={{ height: "8px" }}></div>
        </>
      )}
      {pagamentosArray[4] === "dinheiro" && (
        <>
          <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === 'dinheiro' ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB' }} onClick={() => handleCardClick('dinheiro')}>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              avatar={
                <PaymentsOutlined style={{ color: "#464545" }} />
              }
              title="DINHEIRO"
              titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
            />
          </Card>
          <div style={{ height: "8px" }}></div>
        </>
      )}

      {pagamentosArray[5] === "link" && (
        <>
          <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === 'link de pagamento' ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB' }} onClick={() => handleCardClick('link de pagamento')}>
            <CardHeader
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              avatar={
                <LinkIcon style={{ color: "#464545" }} />
              }
              title="LINK DE PAGAMENTO"
              titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
            />
          </Card>
          <div style={{ height: "8px" }}></div>
        </>
      )}

      {creditodebito[0] && (
        <>
          {creditodebito.map((cartoes) => (
            <>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === cartoes ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB' }} onClick={() => handleCardClick(cartoes)}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={
                    <PaymentOutlined style={{ color: "#464545" }} />
                  }
                  title={cartoes.toUpperCase()}
                  titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
                />
              </Card>
              <div style={{ height: "8px" }}></div>
            </>
          ))}
        </>
      )}

      {vrva[0] && (
        <>
          {vrva.map((vales) => (
            <>
              <Card variant="outlined" sx={{ borderRadius: "8px", width: "100%", border: selectedCard === vales ? `2px solid rgba(${colorPrimary})` : '1px solid #DCDBDB' }} onClick={() => handleCardClick(vales)}>
                <CardHeader
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  avatar={
                    <CardMembershipOutlined style={{ color: "#464545" }} />
                  }
                  title={vales.toUpperCase()}
                  titleTypographyProps={{ fontSize: "14px", fontWeight: "600", color: "#1B1B1B" }}
                />
              </Card>
              <div style={{ height: "8px" }}></div>
            </>
          ))}
        </>
      )}
    </>
  }


  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Forma de pagamento
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Escolha sua forma de pagamento
        </Typography>

        <div style={{ height: "20px" }}></div>

        {returnFormas(formasPagamento)}

        <div style={{ height: "130px" }}></div>

        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "25px 16px 25px 16px" }} onClick={handleFinalizar}>
          <Button variant="contained" size="large" fullWidth style={{ fontWeight: "bold", textTransform: "capitalize" }}>
          Continuar
          </Button>
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default FormaPagamento;