import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab } from '@mui/material';
import { AppShortcutOutlined, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';


function PerfilEstabelecimento() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>

        
      </Container>
    </ThemeProvider>
  );
}

export default PerfilEstabelecimento;