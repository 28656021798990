import { Container, AppBar, Toolbar, IconButton, Typography, TextField, Button } from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import * as React from 'react';

import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';
import LoadingComponent from '../components/LoadingComponent';

import '@fontsource/roboto/400.css';

function InformacoesPerfil() {

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "255, 136, 77");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "100, 116, 139");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "117, 117, 163");
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [modalAlert, setModalAlert] = React.useState(false);
  const navigate = useNavigate();

  const handleOpenModal = (titleModal, descriptionModal) => {
    setTitle(titleModal);
    setDescription(descriptionModal);
    setModalAlert(true);
    handleCloseLoading();
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
  };
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleBack = () => {
    navigate("/perfil", { replace: true, state: { page: "perfil" } })
  };

  const handleCloseModal = () => {
    setModalAlert(false);
  };

  useEffect(() => {
    handleOpenLoading();

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;
    
    if(colorPrimary) {
      // setColorPrimary(Cookies.get("clPmr"))
      // setColorSecondary(Cookies.get("clScr"))
      // setColorTertiary(Cookies.get("clTcr"))
    }
    
    const nomeUsuario = Cookies.get('nmUsr');
    const telefoneUsuario = Cookies.get('tUsr');
    const emailUsuario = Cookies.get('emUsr');
    setNome(nomeUsuario);
    setTelefone(telefoneUsuario);
    setEmail(emailUsuario);
    handleCloseLoading();
  }, []);

  const handleNomeChange = (event) => {
    setNome(event.target.value);
    setIsButtonDisabled(!(email !== "" && telefone !== "" && nome !== ""));
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setIsButtonDisabled(!(email !== "" && telefone !== "" && nome !== ""));
  };

  const handleTelefoneChange = (event) => {
    setTelefone(event.target.value);
    setIsButtonDisabled(!(email !== "" && telefone !== "" && nome !== ""));
  };

  const handleSalvar = () => {
    handleOpenLoading();
    if (email !== "" && telefone !== "" && nome !== "") {
      const idusuarios = Cookies.get('iUsr');
      const urlBase = Cookies.get('UBs');
      axios.post(`${urlBase}/editar-dados.php`, {
        idusuarios: idusuarios,
        telefone: telefone,
        nome: nome
      })
        .then(response => {
          response = response.data
          if (!response) {
            handleOpenModal("", "Não foi possivel salvar seus dados, verifique sua conexão e tente novamente.")
            return
          }
          if (response.resposta === "sucesso atualizar") {
            Cookies.set('nmUsr', nome, { expires: 7 }); 
            Cookies.set('tUsr', telefone, { expires: 7 });
            handleSnack("Informações do perfil salvas com sucesso.");
            setTimeout(handleBack, 3000);
            return
          }
          handleOpenModal("", "Não foi possivel salvar seus dados, verifique sua conexão e tente novamente.")
        })
        .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    }
  };



  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
    <Container>

      <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
        <Toolbar>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
            <ArrowBackOutlined />
          </IconButton>
          Informações de perfil
        </Toolbar>
      </AppBar>

      <LoadingComponent open={loading} />

      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleClose}
        message={msgSnack}
      />

      <Dialog
        open={modalAlert}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Fechar</Button>
        </DialogActions>
      </Dialog>

      <div style={{ height: "83px" }}></div>

      <>

        <Typography style={{ fontSize: "12px", color: "#464444", fontWeight: "bold" }}>
          Nome
        </Typography>

        <div style={{ height: "6px" }}></div>

        <TextField
          fullWidth
          hiddenLabel
          value={nome}
          onChange={handleNomeChange}
          variant="outlined"
          size="small"
          placeholder="Digite seu nome"
          type="text"
        />

        <div style={{ height: "25px" }}></div>

        <Typography style={{ fontSize: "12px", color: "#464444", fontWeight: "bold" }}>
          Telefone
        </Typography>

        <div style={{ height: "6px" }}></div>

        <TextField
          fullWidth
          hiddenLabel
          value={telefone}
          onChange={handleTelefoneChange}
          variant="outlined"
          size="small"
          placeholder="Digite seu telefone"
          type="text"
        />

        <div style={{ height: "25px" }}></div>

        <Typography style={{ fontSize: "12px", color: "#464444", fontWeight: "bold" }}>
          Email
        </Typography>

        <div style={{ height: "6px" }}></div>

        <TextField
          fullWidth
          hiddenLabel
          value={email}
          onChange={handleEmailChange}
          variant="outlined"
          size="small"
          placeholder="Digite seu email"
          type="email"
          disabled
        />

        <div style={{ height: "32px" }}></div>

        <Button fullWidth style={{ textTransform: "capitalize" }} disabled={isButtonDisabled} variant="contained" size="large" onClick={handleSalvar}>Salvar</Button>

      </>

      <div style={{ height: "100px" }}></div>

    </Container>
    </ThemeProvider>
  );
}

export default InformacoesPerfil;