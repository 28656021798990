import { createTheme } from '@mui/material/styles';


export const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name) => {
  if (name.includes(" ")) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`,
    };
  } else {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0][0].toUpperCase()}${name[1][0].toUpperCase()}`,
    };
  }
};

export const createNewTheme = (colorPrimary, colorSecondary, colorTertiary) => {
  return createTheme({
    palette: {
      primary: {
        main: `rgb(${colorPrimary})`,
      },
      secondary: {
        main: `rgb(${colorSecondary})`,
      },
      tertiary: {
        main: `rgb(${colorTertiary})`,
      }
    },
  });
};

export const toReal = (valor) => {
  if (valor) {
    return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  } else {
    return "0"
  }
}

export const capitalizeString = (valor) => {
  return valor.charAt(0).toUpperCase() + valor.slice(1).toLowerCase()
}
