import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, Divider, Snackbar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { AccessTimeOutlined, AppShortcutOutlined, ArrowBackOutlined, AssignmentIndOutlined, AssignmentOutlined, CalendarMonth, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, LocalAtmOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import * as React from 'react';
import LoadingComponent from '../components/LoadingComponent';

function FinalizarAgendamento() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageSnack, setMessageSnack] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState("");
  const [servico, setServico] = useState("");
  const [profissional, setProfissional] = useState("");
  const [data, setData] = useState("");
  const [hora, setHora] = useState("");
  const [telefoneAvulso, setTelefoneAvulso] = useState("");
  const [nomeAvulso, setNomeAvulso] = useState("");

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleClose = () => {
    handleCloseLoading(false);
    setOpen(false);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnack = (title) => {
    setMessageSnack(title)
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleTelefone = (event) => {
    setTelefoneAvulso(event.target.value);
  };

  const handleNome = (event) => {
    setNomeAvulso(event.target.value);
  };

  const handleBack = () => {
    navigate('/forma-pagamento');
  };

  const handleFinalizar = async (isAvulso) => {

    const mainId = Cookies.get('Md');
    const mainName = Cookies.get('Mn');

    const idusuario = Cookies.get('iUsr');
    let json
    if (parseInt(idusuario) > 0) {
      const nomecliente = Cookies.get('nmUsr');
      const telefone = Cookies.get('tUsr');
      json = {
        idestabelecimentos: mainId,
        nomeestabelecimento: mainName,
        idusuarios: idusuario,
        nomecliente: nomecliente,
        telefonecliente: telefone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", ""),
        dataagendamento: `${data} - ${hora}`,
        servico: servico.NM_SV,
        descricao: servico.DSC_SV,
        idprofissional: Cookies.get('IpFSl'),
        nomeprofissional: profissional,
        valor: servico.VL_SV,
        desconto: "0,00",
        formapagamento: selectedCard.toUpperCase(),
      }
    } else {
      if (!isAvulso) {
        setOpen(true)
        return
      }
      json = {
        idestabelecimentos: mainId,
        nomeestabelecimento: mainName,
        idusuarios: "0",
        nomecliente: nomeAvulso,
        telefonecliente: telefoneAvulso.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", ""),
        dataagendamento: `${data} - ${hora}`,
        servico: servico.NM_SV,
        descricao: servico.DSC_SV,
        idprofissional: Cookies.get('IpFSl'),
        nomeprofissional: profissional,
        valor: servico.VL_SV,
        desconto: "0,00",
        formapagamento: selectedCard.toUpperCase(),
      }
    }

    const url = Cookies.get('UBs');
    let endpoint = "cadastro-agendamento.php";
    // if (bloquearCarrinho) {
    //   endpoint = "cadastro-pedido-pontos.php"
    // }
    axios.post(`${url}/${endpoint}`, json)
      .then(response => {
        response = response.data
        console.log(response)
        if (!response) {
          handleSnack("Não foi possivel confirmar seu agendamento, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "pontos insuficientes") {
          handleSnack("Você não possui pontos suficientes para realizar o resgate!")
          return;
        }
        if (response.resposta === "sucesso") {
          // let pontos = parseInt(Cookies.get('pUsr')) - parseInt(Cookies.get('vPt'));
          // if (bloquearCarrinho) {
          //   Cookies.set('pUsr', pontos, { expires: 7 });
          // }
          Cookies.remove('pFSl');
          Cookies.remove('pRSLc');
          Cookies.remove('IpFSl');
          Cookies.remove('fmP');
          navigate("/pedido-finalizado", { replace: true, state: { pedido: response.idpedido, colorPrimary: colorPrimary, colorSecondary: colorSecondary, colorTertiary: colorTertiary } })
          return;
        }
        handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  };

  useEffect(() => {

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    const pagamento = Cookies.get('fmP');
    setSelectedCard(pagamento);
    const profissional = Cookies.get('pFSl');
    setProfissional(profissional);
    const servico = JSON.parse(atob(Cookies.get('pRSLc')));
    setServico(servico);
    let arrDt = Cookies.get('hrAGd');
    if (arrDt) {
      arrDt = arrDt.split(" - ")
    }
    setData(arrDt[0]);
    setHora(arrDt[1]);
  }, []);

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Finalizar
          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
          message={messageSnack}
        />
        <div style={{ height: "70px" }}></div>

        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              handleFinalizar(true);
            },
          }}
        >
          <DialogTitle>Finalizar pedido:</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Para finalizar o seu pedido, por favor digite seu nome e telefone abaixo:
            </DialogContentText>
            <TextField
              margin="dense"
              label="Nome"
              value={nomeAvulso}
              onChange={handleNome}
              placeholder="Nome"
              fullWidth
            />
            <TextField
              margin="dense"
              label="Telefone com DDD"
              value={telefoneAvulso}
              onChange={handleTelefone}
              placeholder="Telefone (só números)"
              fullWidth
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} style={{ textTransform: "capitalize", fontWeight: "700" }}>Voltar</Button>
            <Button type="submit" style={{ textTransform: "capitalize", fontWeight: "700" }}>Continuar</Button>
          </DialogActions>
        </Dialog>

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Finalize seu agendamento
        </Typography>

        <div style={{ height: "20px" }}></div>

        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component="div" style={{ textAlign: "center", fontWeight: "bold", paddingLeft: 10 }}>
              {data}
            </Typography>
          </Box>
          <Typography style={{ position: "relative", marginLeft: "auto", backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", padding: 10, display: "flex" }}>
            <AccessTimeOutlined /> &nbsp; {hora}
          </Typography>
        </Card>

        <div style={{ height: "20px" }}></div>

        <Divider />

        <div style={{ height: "10px" }}></div>
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
          Mais detalhes
        </Typography>

        <div style={{ height: "5px" }}></div>

        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ position: "relative", backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", padding: "10px 23.5px 10px 23.5px", textAlign: 'center', width: 140 }}>
            {/* <AssignmentOutlined /> */}
            Serviço
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <Typography component="div" style={{ textAlign: "center", fontWeight: "bold", paddingLeft: 10 }}>
              {servico.NM_SV}
            </Typography>
          </Box>
        </Card>

        <div style={{ height: "20px" }}></div>

        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ position: "relative", backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", padding: "10px 23.5px 10px 23.5px", textAlign: 'center', width: 140 }}>
            {/* <AssignmentIndOutlined /> */}
            Profissional
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <Typography component="div" style={{ textAlign: "center", fontWeight: "bold", paddingLeft: 10 }}>
              {profissional}
            </Typography>
          </Box>
        </Card>

        <div style={{ height: "20px" }}></div>

        <Card variant="outlined" sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ position: "relative", backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", padding: "10px 25px 10px 25px", textAlign: 'center', width: 140 }}>
            {/* <LocalAtmOutlined /> */}
            Pagamento
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            <Typography component="div" style={{ textAlign: "center", fontWeight: "bold", paddingLeft: 10 }}>
              {selectedCard.toUpperCase()}
            </Typography>
          </Box>
        </Card>

        <div style={{ height: "20px" }}></div>

        <Divider />

        <div style={{ height: "10px" }}></div>
        <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
          Resumo de valores
        </Typography>
        <div style={{ height: "10px" }}></div>
        <Typography style={{ fontSize: "14px", color: "#9ca3af" }}>
          Subtotal <label style={{ float: "right" }}>R$ {servico.VL_SV}</label>
        </Typography>
        <div style={{ height: "5px" }}></div>
        <Typography style={{ fontSize: "14px", color: "#9ca3af" }}>
          Taxa de serviço <label style={{ float: "right" }}>R$ 0,00</label>
        </Typography>
        <div style={{ height: "5px" }}></div>
        <Typography style={{ fontSize: "14px", color: "#9ca3af" }}>
          Desconto <label style={{ float: "right" }}>R$ 0,00</label>
        </Typography>
        <div style={{ height: "5px" }}></div>
        <Typography style={{ fontSize: "14px", color: "#6b7280", fontWeight: "bold" }}>
          Total <label style={{ float: "right" }}>R$ {servico.VL_SV}</label>
        </Typography>

        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "25px 16px 25px 16px" }}>
          <Button fullWidth variant="contained" size="large" style={{ textTransform: "capitalize", fontWeight: "700" }} onClick={() => handleFinalizar(false)}>
            Continuar
          </Button>
        </div>
        <div style={{ height: "130px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default FinalizarAgendamento;