import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, Divider } from '@mui/material';
import { AppShortcutOutlined, ArrowBackOutlined, CalendarMonth, Check, Close, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import * as React from 'react';

function Horarios() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";
  const [horarioSelect, setHorarioSelect] = useState("");

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [secondary, setSecondary] = React.useState(false);
  const [horarios, setHorarios] = useState(null);
  const [horariosIndisponiveis, setHorariosIndisponiveis] = useState("");
  const [horariosPassados, setHorariosPassados] = useState("");

  const navigate = useNavigate();

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleBack = () => {
    navigate('/escolher-profissional');
  };

  useEffect(() => {
    // handleOpenLoading();
    let urlBase = Cookies.get('UBs');
    if (!urlBase) {
      urlBase = window.location.href;
      if (urlBase.includes("?")) {
        let arrUrl = urlBase.split("?");
        urlBase = arrUrl[0];
      }
      urlBase = `${urlBase}/api`;
    }

    getHorariosDisponiveis(urlBase);
  }, []);

  const getHorariosDisponiveis = (url) => {
    axios.get(`${url}/horarios-disponiveis.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        response = response.data
        getHorariosIndisponiveis(url, response);
        setHorarios(response);
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getHorariosIndisponiveis = (url, horariosdisponiveis) => {
    axios.get(`${url}/horarios-indisponiveis.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        response = response.data
        const horArr = response.resposta.split("___");
        let horariosfora = "", horariospassados = ""
        for (const agendado of horArr) {
          let count = 0, diaAtual = 0
          let currentDay = "", isSet = false
          for (const disponiveis of horariosdisponiveis) {
            if (disponiveis.includes(", ") && !isSet) {
              currentDay = disponiveis
              diaAtual += 1
            } else {
              let agenda = `${currentDay} - ${disponiveis}`
              if(diaAtual === 0 || diaAtual === 1) {
                const timeString = agenda.split("-")[1].trim();
                const [hours, minutes] = timeString.split(":").map(Number);
                const now = new Date();
                const targetTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);
                
                if (now > targetTime) {
                  horariospassados = `${disponiveis}___${count}, `+horariospassados
                }
              }
              if (agenda === agendado) {
                horariosfora = `${disponiveis}___${count}, `+horariosfora
              }
            }
            count += 1
          }
        }
        setHorariosIndisponiveis(horariosfora)
        setHorariosPassados(horariospassados)

      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const handleHorario = (hora) => {
    setHorarioSelect(hora)
  };

  const handlePagamento = () => {
    let currentDay = "", isSet = false
    const horArr = horarioSelect.split("___");
    let count = 0
    if (horArr.length === 1) {
      handleSnack("Selecione um horario para prosseguir")
    } else {
      for (const horario of horarios) {
        if (horario.includes(", ") && !isSet) {
          currentDay = horario
        }
        if (horArr[1] == count) {
          isSet = true
        }
        count += 1
      }
      Cookies.set('hrAGd', `${currentDay} - ${horArr[0]}`, { expires: 7 });
      navigate('/forma-pagamento');
    }
  };

  const validaHorario = (horario, index) => {
    let comparer = `${horario}___${index}`
    if (horariosPassados && horariosPassados.includes(comparer)) {
      return <Chip variant={"filled"} label={horario} icon={<Check style={{ color: "#FFF" }} />} style={{ opacity: 0.4, borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "green", color: "#FFF" }} />
    }
    if (horariosIndisponiveis && horariosIndisponiveis.includes(comparer)) {
      return <Chip variant={"filled"} label={horario} icon={<Close style={{ color: "#FFF" }} />} style={{ opacity: 0.4, borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "#dc2626", color: "#FFF" }} />
    }
    return <Chip variant={horarioSelect === `${horario}___${index}` ? "filled" : "outlined"} label={horario} icon={horarioSelect === `${horario}___${index}` ? <Check style={{ color: "#FFF" }} /> : ""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: horarioSelect === `${horario}___${index}` ? "#1B1B1B" : "", color: horarioSelect === `${horario}___${index}` ? "#FFF" : "#1B1B1B" }} onClick={() => handleHorario(`${horario}___${index}`)} />
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Horários
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Escolha seu horário
        </Typography>

        <div style={{ height: "20px" }}></div>

        {horarios && (
          <>
            {horarios.map((horario, index) => (
              <>
                {horario.includes(", ") ?
                  <>
                    {index !== 0 && (
                      <>
                        <div style={{ height: "20px" }}></div>
                        <Divider />
                        <div style={{ height: "20px" }}></div>
                      </>
                    )}
                    <Typography style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "-5px" }}>
                      {horario}
                    </Typography>
                  </>
                  :
                  <>
                    {validaHorario(horario, index)}
                  </>
                }
              </>
            ))}
          </>
        )}


        {/* <Typography style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "-5px" }}>
          Terça, 29 abril
        </Typography>

        <Chip variant={segment === "Todos" ? "filled" : "outlined"} label="09:00" icon={segment === "Todos" ? <Check style={{ color: "#FFF" }} /> : ""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: segment === "Todos" ? "#1B1B1B" : "", color: segment === "Todos" ? "#FFF" : "#1B1B1B" }} onClick={() => handleHorario("Todos")} />
        <Chip variant={"outlined"} label={"10:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"11:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"12:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"13:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"14:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"15:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"16:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"17:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"18:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />

        <div style={{ height: "20px" }}></div>
        <Divider />

        <div style={{ height: "20px" }}></div>
        <Typography style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "-5px" }}>
          Terça, 29 abril
        </Typography>

        <Chip variant={"outlined"} label={"10:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"11:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"12:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"13:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"14:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"15:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"16:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"17:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"18:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />

        <div style={{ height: "20px" }}></div>
        <Divider />

        <div style={{ height: "20px" }}></div>
        <Typography style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "-5px" }}>
          Terça, 29 abril
        </Typography>

        <Chip variant={"outlined"} label={"10:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"11:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"12:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"13:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"14:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"15:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"16:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"17:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />
        <Chip variant={"outlined"} label={"18:00"} icon={""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "", color: "#1B1B1B" }} />

        <div style={{ height: "20px" }}></div>
        <Divider /> */}

        <div style={{ height: "130px" }}></div>


        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "25px 16px 25px 16px" }} onClick={handlePagamento}>
          <Button variant="contained" size="large" fullWidth style={{ fontWeight: "bold", textTransform: "capitalize" }}>
            Continuar
          </Button>
        </div>

      </Container>
    </ThemeProvider>
  );
}

export default Horarios;