import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, Divider, IconButton, SwipeableDrawer } from '@mui/material';
import { AccessTimeOutlined, AccountBoxOutlined, AccountCircleOutlined, AppShortcutOutlined, ArrowBackOutlined, CalendarMonth, Check, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, TodayOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { capitalizeString, createNewTheme } from '../functions/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'js-cookie';

function MeusAgendamentos(props) {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";
  const { windows } = props;

  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  const [agendamentos, setAgendamentos] = useState(null);
  const [itemSelected, setItemSelected] = useState(null);
  const [open, setOpen] = useState(false);

  const container = windows !== undefined ? () => windows().document.body : undefined;

  const navigate = useNavigate();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleBack = () => {
    navigate('/');
  };

  const handlePerfil = () => {
    navigate('/perfil');
  };

  useEffect(() => {
    // handleOpenLoading();
    let urlBase = Cookies.get('UBs');
    if (!urlBase) {
      urlBase = window.location.href;
      if (urlBase.includes("?")) {
        let arrUrl = urlBase.split("?");
        urlBase = arrUrl[0];
      }
      urlBase = `${urlBase}/api`;
    }

    let isLogged = Cookies.get('isLogged');
    setIsLogged(isLogged)
    getAgendamentos(urlBase);
  }, []);

  const getAgendamentos = (url) => {
    let idusuarios = Cookies.get('iUsr');
    axios.get(`${url}/agendamentos.php?idusuarios=${idusuarios}`, {
      idusuarios: idusuarios
    })
      .then(response => {
        response = response.data
        let ultimodia = "", count = 0
        for (const agenda of response) {
          const arrAgd = agenda.DT_AGD.split(" - ")
          if (ultimodia === "" || ultimodia !== arrAgd[0]) {
            response[count].title = arrAgd[0]
            ultimodia = arrAgd[0]
          }
          response[count].hora = arrAgd[1]
          response[count].data = arrAgd[0]
          count += 1
        }
        setAgendamentos(response);
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const handleDetalhes = (agenda) => {
    setOpen(true)
    setItemSelected(agenda)
  };
  
  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Meus agendamentos
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <SwipeableDrawer
          container={container}
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={true}
          ModalProps={{
            keepMounted: true,
          }}
        >

          <div style={{ backgroundColor: "#D6D6D6", height: "4px", width: "32px", borderRadius: "100px", margin: "15px auto" }}></div>

          {itemSelected && (
            <>
              <Typography style={{ fontSize: "18px", fontWeight: "bold", paddingLeft: "16px" }}>
                <b style={{ fontSize: 22 }}>Detalhes do cliente</b>
                <div style={{ height: 15 }} />
                {capitalizeString(itemSelected.NM_CL)}
              </Typography>
              <Typography style={{ fontSize: "14px", paddingLeft: "16px", color: "#94a3b8" }}>
                Telefone: {capitalizeString(itemSelected.TL_CL)}<br />
                Profissional: {capitalizeString(itemSelected.PF_PD)}
              </Typography>
              <div style={{ padding: 16 }}><Divider /></div>
              <Typography style={{ fontSize: "16px", paddingLeft: "16px" }}>
                <b style={{ fontSize: 22 }}>Detalhes do agendamento</b>
                <div style={{ height: 15 }} />
                Serviço: {itemSelected.SV_PD}<br />
                Data: {itemSelected.data}<br />
                Hora: <b>{itemSelected.hora}</b><br />
              </Typography>
              <div style={{ padding: 16 }}><Divider /></div>
              <Typography style={{ fontSize: "16px", paddingLeft: "16px" }}>
                <b style={{ fontSize: 22 }}>Detalhes do pagamento</b>
                <div style={{ height: 15 }} />
                Forma de pagamento: {itemSelected.FM_PGT}
                <div style={{ height: 15 }} />
                Subtotal: <b style={{ float: "right", paddingRight: 16 }}>R$ {itemSelected.VL_PD}</b><br />
                Taxa de serviço: <b style={{ float: "right", paddingRight: 16 }}>R$ 0,00</b><br />
                Desconto: <b style={{ float: "right", paddingRight: 16 }}>R$ 0,00</b><br />
                <b style={{ fontSize: 20 }}>Total: <b style={{ float: "right", paddingRight: 16 }}>R$ {itemSelected.VL_PD}</b><br /></b>
              </Typography>
              <div style={{ height: "20px" }}></div>
            </>
          )}
        </SwipeableDrawer>

        {isLogged ? (
          <>
            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
              Veja seus agendamentos
            </Typography>

            <div style={{ height: "20px" }}></div>
            <Grid container spacing={2} style={{ paddingTop: 16 }}>
              {agendamentos && (
                <>
                  {agendamentos.map((agenda) => (
                    <>
                      {agenda.title && (
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "-5px" }}>
                            {agenda.title}
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={12} md={2}>
                        <Card variant="outlined" sx={{ borderRadius: "8px" }}>
                          <Typography style={{ fontWeight: "bold", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "flex", alignItems: "center", background: "#f1f5f9", color: "#334155", padding: 6 }}>
                            <AccountBoxOutlined /> &nbsp; {capitalizeString(agenda.PF_PD)}
                          </Typography>
                          <Divider></Divider>
                          <Box style={{ padding: "6px 10px 6px 8px" }}>
                            <div style={{ height: 5 }}></div>
                            <Typography style={{ fontSize: 13, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", display: "flex", alignItems: "center" }}>
                              <TodayOutlined style={{ fontSize: 18 }} /> &nbsp; {agenda.SV_PD}
                            </Typography>
                            <Typography style={{ fontSize: 13, display: "flex", alignItems: "center" }}>
                              <AccessTimeOutlined style={{ fontSize: 18 }} /> &nbsp; <b>{agenda.hora}</b>
                            </Typography>
                            <div style={{ height: 5 }}></div>
                            <Button variant="contained" fullWidth size="small" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={() => handleDetalhes(agenda)}>Ver detalhes</Button>
                            <div style={{ height: 5 }}></div>
                          </Box>
                        </Card>
                      </Grid>
                    </>
                  ))}
                </>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Typography style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", margin: "0" }}>
              <Typography style={{ textAlign: "center", fontSize: "12px", color: "#999", minWidth: "300px" }}>

                <img src="https://dedicado.mely.online/imagens/noimage.jpg" style={{ width: 400 }} />

                <div style={{ height: "10px" }}></div>

                Você precisa estar logado para ver os seus pedidos. <br />Que tal realizar o login ou cadastrar-se agora?

                <div style={{ height: "20px" }}></div>

                <Button variant="text" style={{ fontWeight: "bold", textTransform: "capitalize" }} onClick={handlePerfil}>Ir para o login</Button>

                <div style={{ height: "10px" }}></div>

              </Typography>
            </Typography>
          </>
        )}

      </Container>
    </ThemeProvider>
  );
}

export default MeusAgendamentos;