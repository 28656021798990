import { Container, Grid, AppBar, Toolbar, Button, TextField, Chip, Typography, Card, CardActionArea, CardMedia, CardContent, Box, Avatar, Skeleton, Fab, IconButton, Divider, Snackbar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { AppShortcutOutlined, ArrowBackOutlined, CalendarMonth, Check, Close, EventNote, Facebook, FavoriteBorder, History, InfoOutlined, Instagram, LinkOutlined, PersonOutline, ShareOutlined, Star, Telegram, TimerOutlined, WhatsApp } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../../functions/utils';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import * as React from 'react';

function HorariosManual() {

  const colorPrimary = "255, 136, 77";
  const colorSecondary = "100, 116, 139";
  const colorTertiary = "117, 117, 163";
  const [horarioSelect, setHorarioSelect] = useState("");

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const [retirar, setRetirar] = React.useState(false);
  const [horarios, setHorarios] = useState(null);
  const [items, setItems] = useState([]);
  const [servico, setServico] = useState("");
  const [formasPagamento, setFormasPagamento] = useState("");
  const [formaPagamento, setFormaPagamento] = useState("");
  const [nomeCliente, setNomeCliente] = useState("");
  const [telefoneCliente, setTelefone] = useState("");
  const [profissionais, setProfissionais] = useState([]);
  const [profissional, setProfissional] = useState("");

  const [horariosIndisponiveis, setHorariosIndisponiveis] = useState("");
  const [horariosPassados, setHorariosPassados] = useState("");

  const navigate = useNavigate();

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleClose = () => {
    handleCloseLoading(false);
    setOpen(false);
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
    handleCloseLoading();
  };

  const handleBack = () => {
    navigate('/estabelecimento/agenda');
  };

  useEffect(() => {
    // handleOpenLoading();
    let urlBase = Cookies.get('UBs');
    if (!urlBase) {
      urlBase = window.location.href;
      if (urlBase.includes("?")) {
        let arrUrl = urlBase.split("?");
        urlBase = arrUrl[0];
      }
      urlBase = `${urlBase}/api`;
    }

    getHorariosDisponiveis(urlBase);
    getProdutos(urlBase);
    getProfissionais(urlBase);
    getInfos();
  }, []);

  const getProfissionais = (url) => {
    axios.get(`${url}/api/profissionais.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        response = response.data
        setProfissionais(response);
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getInfos = () => {
    const mainId = Cookies.get('Md');
    const urlBase = Cookies.get('UBs');
    axios.post(`${urlBase}/api/formas-pagamento.php`, {
      idestabelecimentos: mainId
    })
      .then(response => {
        setFormasPagamento(response.data[0]);
        handleCloseLoading();
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getProdutos = (url) => {
    axios.get(`${url}/api/servicos.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        response = response.data
        // ajusta as categorias
        let ultimaCategoria = "", ultimaSubcategoria = "", ultimoProduto = ""
        for (const produto of response) {
          const partes = produto.CT_SV.split(' - ');
          const categoriaNumero = partes[0];
          const categoriaOriginal = partes[1];
          const categoriaProduto = partes[2];
          produto.categoriaNumero = categoriaNumero
          produto.categoriaOriginal = categoriaOriginal
          produto.categoriaProduto = categoriaProduto
          produto.subcategoriaOriginal = produto.SCT_SV
          if (ultimaCategoria == "" || ultimaCategoria != produto.CT_SV) {
            if (!produto.tipo) {
              if (produto.SCT_SV != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.SCT_SV)) {
                ultimaCategoria = produto.CT_SV
                ultimaSubcategoria = produto.SCT_SV
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                continue
              } else {
                if (produto.SCT_SV == "") {
                  ultimaSubcategoria = produto.SCT_SV
                }
                ultimaCategoria = produto.CT_SV
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                continue
              }
            } else {
              if (produto.SCT_SV != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.SCT_SV)) {
                ultimaCategoria = produto.CT_SV
                ultimaSubcategoria = produto.SCT_SV
                ultimoProduto = categoriaProduto
                produto.isFirst = "true"
                continue
              }
            }
          } else {
            if (produto.SCT_SV != "" && (ultimaSubcategoria == "" || ultimaSubcategoria != produto.SCT_SV)) {
              ultimaCategoria = produto.CT_SV
              ultimaSubcategoria = produto.SCT_SV
              ultimoProduto = categoriaProduto
              produto.isFirst = "true"
              continue
            }
          }

          if (produto.tipo) {
            if (categoriaProduto !== ultimoProduto) {
              produto.isFirst = "true"
            }
          }
          ultimoProduto = categoriaProduto

          produto.CT_SV = ""
          produto.SCT_SV = ""
        }
        handleCloseLoading();
        setItems(response);
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getHorariosDisponiveis = (url) => {
    axios.get(`${url}/api/horarios-disponiveis.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        response = response.data
        getHorariosIndisponiveis(url, response);
        setHorarios(response);
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const getHorariosIndisponiveis = (url, horariosdisponiveis) => {
    axios.get(`${url}/api/horarios-indisponiveis.php?idestabelecimentos=1`, {
      idestabelecimentos: "1"
    })
      .then(response => {
        response = response.data
        const horArr = response.resposta.split("___");
        let horariosfora = "", horariospassados = ""
        for (const agendado of horArr) {
          let count = 0, diaAtual = 0
          let currentDay = "", isSet = false
          for (const disponiveis of horariosdisponiveis) {
            if (disponiveis.includes(", ") && !isSet) {
              currentDay = disponiveis
              diaAtual += 1
            } else {
              let agenda = `${currentDay} - ${disponiveis}`
              if (diaAtual === 0 || diaAtual === 1) {
                const timeString = agenda.split("-")[1].trim();
                const [hours, minutes] = timeString.split(":").map(Number);
                const now = new Date();
                const targetTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes);

                if (now > targetTime) {
                  horariospassados = `${disponiveis}___${count}, ` + horariospassados
                }
              }
              if (agenda === agendado) {
                horariosfora = `${disponiveis}___${count}, ` + horariosfora
              }
            }
            count += 1
          }
        }
        setHorariosIndisponiveis(horariosfora)
        setHorariosPassados(horariospassados)

      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
  }

  const handleHorario = (hora) => {
    setHorarioSelect(hora)
  };

  const handlePagamento = () => {
    let currentDay = "", isSet = false
    const horArr = horarioSelect.split("___");
    let count = 0
    if (horArr.length === 1) {
      handleSnack("Selecione um horario para prosseguir")
    } else {
      setOpen(true);
    }
  };

  const handleServico = (event) => {
    setServico(event.target.value)
  };

  const handleProfissional = (event) => {
    setProfissional(event.target.value)
  };

  const handleFormaPagamento = (event) => {
    setFormaPagamento(event.target.value)
  };

  const handleRetirar = (event) => {
    setRetirar(event.target.checked)
  };

  const handleNome = (event) => {
    setNomeCliente(event.target.value)
  };

  const handleTelefone = (event) => {
    setTelefone(event.target.value)
  };

  const handleFinalizar = () => {
    const mainId = Cookies.get('Md');
    const mainName = Cookies.get('Mn');

    const urlBase = Cookies.get('UBs');

    if (!retirar) {
      if (!nomeCliente) {
        handleSnack("Digite o nome do cliente para prosseguir")
        return
      }
      if (!telefoneCliente) {
        handleSnack("Digite o telefone do cliente para prosseguir")
        return
      }
      if (!servico) {
        handleSnack("Selecione o serviço para prosseguir")
        return
      }
      if (!profissional) {
        handleSnack("Selecione o profissional para prosseguir")
        return
      }
      if (!formaPagamento) {
        handleSnack("Selecione a forma de pagamento para prosseguir")
        return
      }
    }
    const servicoSelecionado = items.filter(item => item.ID_SV_EST === servico)[0]
    const profissionalSelecionado = profissionais.filter(item => item.IDPF_EST === profissional)[0]
    const pagamentoSelecionado = formaPagamento
    let horarioSelecionado = ""
    let currentDay = "", isSet = false
    const horArr = horarioSelect.split("___");
    let count = 0
    if (horArr.length === 1) {
      handleSnack("Selecione um horario para prosseguir")
    } else {
      for (const horario of horarios) {
        if (horario.includes(", ") && !isSet) {
          currentDay = horario
        }
        if (horArr[1] == count) {
          isSet = true
        }
        count += 1
      }
      horarioSelecionado = `${currentDay} - ${horArr[0]}`;
    }

    let json
    if (retirar) {
      json = {
        idestabelecimentos: mainId,
        nomeestabelecimento: mainName,
        idusuarios: 0,
        nomecliente: "-",
        telefonecliente: "-",
        dataagendamento: horarioSelecionado,
        servico: "-",
        descricao: "-",
        idprofissional: 0,
        nomeprofissional: "-",
        valor: "-",
        desconto: "0,00",
        formapagamento: "-",
      }
    } else {
      json = {
        idestabelecimentos: mainId,
        nomeestabelecimento: mainName,
        idusuarios: 0,
        nomecliente: nomeCliente,
        telefonecliente: telefoneCliente.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", ""),
        dataagendamento: horarioSelecionado,
        servico: servicoSelecionado.NM_SV,
        descricao: servicoSelecionado.DSC_SV,
        idprofissional: profissionalSelecionado.IDPF_EST,
        nomeprofissional: profissionalSelecionado.NM_PF,
        valor: servicoSelecionado.VL_SV,
        desconto: "0,00",
        formapagamento: pagamentoSelecionado.toUpperCase(),
      }
    }
    
    let endpoint = "cadastro-agendamento.php";
    // if (bloquearCarrinho) {
    //   endpoint = "cadastro-pedido-pontos.php"
    // }
    axios.post(`${urlBase}/api/${endpoint}`, json)
      .then(response => {
        response = response.data
        if (!response) {
          handleSnack("Não foi possivel confirmar seu agendamento, verifique sua conexão e tente novamente.")
          return;
        }
        if (response.resposta === "pontos insuficientes") {
          handleSnack("Você não possui pontos suficientes para realizar o resgate!")
          return;
        }
        if (response.resposta === "sucesso") {
          setFormaPagamento("");
          setNomeCliente("");
          setTelefone("");
          setProfissional("");
          setFormaPagamento("");
          setServico("");
          getHorariosDisponiveis(urlBase);
          setOpen(false);
          handleSnack("Horario agendado com sucesso")
          return;
        }
        handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente.")
      })
      .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));    
  };

  const returnFormas = (formas) => {
    if (!formas || !formas.formas_pagamento) {
      return [];
    }

    const pagamentosArray = formas.formas_pagamento.split("--");
    const creditodebito = pagamentosArray[1] ? pagamentosArray[1].split(",") : [];
    const vrva = pagamentosArray[2] ? pagamentosArray[2].split(",") : [];

    if (pagamentosArray[3]) {
      Cookies.set('cvP', pagamentosArray[3], { expires: 7 });
    }

    let items = [];

    if (pagamentosArray[3]) {
      items.push(
        <MenuItem key="pix" value="pix">
          PIX
        </MenuItem>
      );
    }
    if (pagamentosArray[4] === "dinheiro") {
      items.push(
        <MenuItem key="dinheiro" value="dinheiro">
          DINHEIRO
        </MenuItem>
      );
    }
    if (pagamentosArray[5] === "link") {
      items.push(
        <MenuItem key="link" value="link">
          LINK DE PAGAMENTO
        </MenuItem>
      );
    }

    creditodebito.forEach((cartoes) => {
      if (cartoes) {
        items.push(
          <MenuItem key={cartoes} value={cartoes}>
            {cartoes.toUpperCase()}
          </MenuItem>
        );
      }
    });

    vrva.forEach((vales) => {
      if (vales) {
        items.push(
          <MenuItem key={vales} value={vales}>
            {vales.toUpperCase()}
          </MenuItem>
        );
      }
    });

    return items;
  };

  const validaHorario = (horario, index) => {
    let comparer = `${horario}___${index}`
    if (horariosPassados && horariosPassados.includes(comparer)) {
      return <Chip variant={"filled"} label={horario} icon={<Check style={{ color: "#FFF" }} />} style={{ opacity: 0.4, borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "green", color: "#FFF" }} />
    }
    if (horariosIndisponiveis && horariosIndisponiveis.includes(comparer)) {
      return <Chip variant={"filled"} label={horario} icon={<Close style={{ color: "#FFF" }} />} style={{ opacity: 0.4, borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: "#dc2626", color: "#FFF" }} />
    }
    return <Chip variant={horarioSelect === `${horario}___${index}` ? "filled" : "outlined"} label={horario} icon={horarioSelect === `${horario}___${index}` ? <Check style={{ color: "#FFF" }} /> : ""} style={{ borderRadius: "4px", marginRight: "8px", marginTop: "10px", backgroundColor: horarioSelect === `${horario}___${index}` ? "#1B1B1B" : "", color: horarioSelect === `${horario}___${index}` ? "#FFF" : "#1B1B1B" }} onClick={() => handleHorario(`${horario}___${index}`)} />
  }

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>
        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Horários
          </Toolbar>
        </AppBar>

        <div style={{ height: "70px" }}></div>

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
          message={msgSnack}
        />

        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              handleFinalizar(true);
            },
          }}
        >
          <DialogTitle>Finalizar agendamento:</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Para finalizar o seu agendamento, por favor complete as informações abaixo:
            </DialogContentText>
            <div style={{ height: 8 }} />
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={retirar} onChange={handleRetirar} />} label="Apenas retirar o horario" />
            </FormGroup>
            {!retirar && (
              <>
                <div style={{ height: 8 }} />
                <TextField
                  margin="dense"
                  label="Nome"
                  value={nomeCliente}
                  onChange={handleNome}
                  placeholder="Nome"
                  fullWidth
                />
                <div style={{ height: 18 }} />
                <TextField
                  margin="dense"
                  label="Telefone"
                  value={telefoneCliente}
                  onChange={handleTelefone}
                  placeholder="Telefone"
                  fullWidth
                />
                <div style={{ height: 18 }} />
                <FormControl fullWidth>
                  <InputLabel id="select-label">Selecione o serviço</InputLabel>
                  <Select
                    labelId="select-label"
                    label="Selecione o serviço"
                    value={servico}
                    onChange={handleServico}
                  >
                    {items.map((produto) => (
                      <MenuItem key={produto.ID_SV_EST} value={produto.ID_SV_EST}>
                        {produto.NM_SV}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ height: 22 }} />
                <FormControl fullWidth>
                  <InputLabel id="select-label">Selecione o profissional</InputLabel>
                  <Select
                    labelId="select-label"
                    label="Selecione o profissional"
                    value={profissional}
                    onChange={handleProfissional}
                  >
                    {profissionais.map((produto) => (
                      <MenuItem key={produto.IDPF_EST} value={produto.IDPF_EST}>
                        {produto.NM_PF}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div style={{ height: 22 }} />
                <FormControl fullWidth>
                  <InputLabel id="select-label">Selecione a forma de pagamento</InputLabel>
                  <Select
                    labelId="select-label"
                    label="Selecione a forma de pagamento"
                    value={formaPagamento}
                    onChange={handleFormaPagamento}
                  >
                    {returnFormas(formasPagamento)}
                  </Select>
                </FormControl>
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose} style={{ textTransform: "capitalize", fontWeight: "700" }}>Voltar</Button>
            <Button type="submit" style={{ textTransform: "capitalize", fontWeight: "700" }}>Continuar</Button>
          </DialogActions>
        </Dialog>

        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Escolha seu horário
        </Typography>

        <div style={{ height: "20px" }}></div>

        {horarios && (
          <>
            {horarios.map((horario, index) => (
              <>
                {horario.includes(", ") ?
                  <>
                    {index !== 0 && (
                      <>
                        <div style={{ height: "20px" }}></div>
                        <Divider />
                        <div style={{ height: "20px" }}></div>
                      </>
                    )}
                    <Typography style={{ fontSize: "15px", fontWeight: "bold", marginBottom: "-5px" }}>
                      {horario}
                    </Typography>
                  </>
                  :
                  <>
                    {validaHorario(horario, index)}
                  </>
                }
              </>
            ))}
          </>
        )}

        <div style={{ height: "130px" }}></div>


        <div style={{ borderTop: `solid 1px rgba(${colorPrimary})`, position: "fixed", bottom: "0", right: "0", left: "0", backgroundColor: "white", padding: "25px 16px 25px 16px" }} onClick={handlePagamento}>
          <Button variant="contained" size="large" fullWidth style={{ fontWeight: "bold", textTransform: "capitalize" }}>
            Continuar
          </Button>
        </div>

      </Container>
    </ThemeProvider>
  );
}

export default HorariosManual;