import { Container, AppBar, Toolbar, IconButton, Typography, Button, OutlinedInput, InputAdornment } from '@mui/material';
import { ArrowBackOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { createNewTheme } from '../functions/utils';
import * as React from 'react';

import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';
import LoadingComponent from '../components/LoadingComponent';

import '@fontsource/roboto/400.css';

function Senha() {

  const state = useLocation().state;
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [msgSnack, setMsgSnack] = useState(false);
  const page = state ? state.page : 'home';
  const [colorPrimary, setColorPrimary] = useState(state ? state.colorPrimary : "255, 136, 77");
  const [colorSecondary, setColorSecondary] = useState(state ? state.colorSecondary : "100, 116, 139");
  const [colorTertiary, setColorTertiary] = useState(state ? state.colorTertiary : "117, 117, 163");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordAntigo, setShowPasswordAntigo] = useState(false);
  const [showPasswordConfirmar, setShowPasswordConfirmar] = useState(false);
  const [senha, setSenha] = useState('');
  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [modalAlert, setModalAlert] = React.useState(false);
  const navigate = useNavigate();

  const handleOpenModal = (titleModal, descriptionModal) => {
    setTitle(titleModal);
    setDescription(descriptionModal);
    setModalAlert(true);
    handleCloseLoading();
  };

  const handleOpenLoading = () => {
    setLoading(true);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleSnack = (title) => {
    setMsgSnack(title);
    setOpenSnack(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  };

  const handleBack = () => {
    navigate("/perfil", { replace: true, state: { page: "perfil" } })
  };

  const handleCloseModal = () => {
    setModalAlert(false);
  };

  useEffect(() => {

    const mainName = Cookies.get('Mn');
    document.title = mainName;
    const urlBase = Cookies.get('UBs');
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = `${urlBase.replaceAll("/api", "")}/imagens/logo-store.png`;

    if (colorPrimary) {
      // setColorPrimary(Cookies.get("clPmr"))
      // setColorSecondary(Cookies.get("clScr"))
      // setColorTertiary(Cookies.get("clTcr"))
    }

  }, []);

  const handleSalvar = () => {
    handleOpenLoading();
    if (senha !== "" && senhaAntiga !== "" && confirmarSenha !== "") {
      if (senha !== confirmarSenha) {
        handleOpenModal("Senhas não conferem", "Por favor, verifique se a nova senha e a confirmação estão exatamente iguais.")
        return
      }

      const idusuarios = Cookies.get('iUsr');
      const urlBase = Cookies.get('UBs');
      axios.post(`${urlBase}/editar-senha.php`, {
        idusuarios: idusuarios,
        senha: senhaAntiga,
        newSenha: senha
      })
        .then(response => {
          response = response.data
          if (!response) {
            handleOpenModal("", "Não foi possivel salvar sua senha, verifique sua conexão e tente novamente.")
            return
          }
          if (response.resposta === "senha invalida") {
            handleOpenModal("Senhas atual inválida", "Por favor, verifique se a senha atual foi digitada corretamente.")
            return
          }
          if (response.resposta === "senha curta") {
            handleOpenModal("Senhas curta", "Por favor, digite uma senha de pelo menos 7 caracteres")
            return
          }
          if (response.resposta === "falha atualizar") {
            handleOpenModal("", "Não foi possivel salvar sua senha, verifique sua conexão e tente novamente.")
            return
          }
          if (response.resposta === "sucesso atualizar") {
            handleSnack("Sua nova senha foi salva com sucesso.");
            setTimeout(handleBack, 3000);
            return
          }
          handleOpenModal("", "Não foi possivel salvar sua senha, verifique sua conexão e tente novamente.")
        })
        .catch(error => handleSnack("Não foi possivel conectar ao servidor, verifique sua conexão e tente novamente."));
    } else {
      handleOpenModal("Campos vazios", "Por favor, preencha todos os campos para continuar.")
      return
    }
  };

  const handleMenuClickShowPassword = () => setShowPassword((show) => !show);

  const handleMenuClickShowPasswordAntigo = () => setShowPasswordAntigo((showAntigo) => !showAntigo);

  const handleMenuClickShowPasswordConfirmar = () => setShowPasswordConfirmar((showConfirmar) => !showConfirmar);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (event) => {
    setSenha(event.target.value);
  };

  const handlePasswordAntigoChange = (event) => {
    setSenhaAntiga(event.target.value);
  };

  const handlePasswordConfirmarChange = (event) => {
    setConfirmarSenha(event.target.value);
  };

  return (
    <ThemeProvider theme={createNewTheme(colorPrimary, colorSecondary, colorTertiary)}>
      <Container>

        <AppBar position="absolute" style={{ backgroundColor: `rgba(${colorPrimary})`, color: "#FFF", fontWeight: "bold", boxShadow: "none", fontFamily: "roboto" }}>
          <Toolbar>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} style={{ color: "#FFF" }} onClick={handleBack}>
              <ArrowBackOutlined />
            </IconButton>
            Senha
          </Toolbar>
        </AppBar>

        <LoadingComponent open={loading} />

        <Snackbar
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClose}
          message={msgSnack}
        />

        <Dialog
          open={modalAlert}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Fechar</Button>
          </DialogActions>
        </Dialog>

        <div style={{ height: "83px" }}></div>

        <>

          <Typography style={{ fontSize: "12px", color: "#464444", fontWeight: "bold" }}>
            Senha atual
          </Typography>

          <div style={{ height: "6px" }}></div>

          <OutlinedInput
            fullWidth
            hiddenLabel
            value={senhaAntiga}
            onChange={handlePasswordAntigoChange}
            variant="outlined"
            size="small"
            placeholder="Digite sua senha"
            type={showPasswordAntigo ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleMenuClickShowPasswordAntigo}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPasswordAntigo ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />

          <div style={{ height: "10px" }}></div>

          <Typography style={{ fontSize: "12px", color: "#464444", fontWeight: "bold" }}>
            Nova senha
          </Typography>

          <div style={{ height: "6px" }}></div>

          <OutlinedInput
            fullWidth
            hiddenLabel
            value={senha}
            onChange={handlePasswordChange}
            variant="outlined"
            size="small"
            placeholder="Digite sua senha"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleMenuClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />

          <div style={{ height: "10px" }}></div>

          <Typography style={{ fontSize: "12px", color: "#464444", fontWeight: "bold" }}>
            Confirmar nova senha
          </Typography>

          <div style={{ height: "6px" }}></div>

          <OutlinedInput
            fullWidth
            hiddenLabel
            value={confirmarSenha}
            onChange={handlePasswordConfirmarChange}
            variant="outlined"
            size="small"
            placeholder="Digite sua senha"
            type={showPasswordConfirmar ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleMenuClickShowPasswordConfirmar}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPasswordConfirmar ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />

          <div style={{ height: "32px" }}></div>

          <Button fullWidth style={{ textTransform: "capitalize" }} disabled={isButtonDisabled} variant="contained" size="large" onClick={handleSalvar}>Salvar</Button>

        </>

        <div style={{ height: "100px" }}></div>

      </Container>
    </ThemeProvider>
  );
}

export default Senha;